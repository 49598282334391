import {TripData} from "./tripData";
import {jsonMember, jsonObject, TypedJSON} from "typedjson";
import dayjs from "dayjs";


@jsonObject()
export class TripPoint {

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    time: dayjs.Dayjs;

    @jsonMember(TripData)
    data: TripData;

    get latitude(): number {
        return this.data.latitude;
    }

    get longitude(): number {
        return this.data.longitude;
    }

    get speed(): number {
        return this.data.speed;
    }

    get address(): string {
        return this.data.address;
    }

}

export const tripPointSerializer = new TypedJSON(TripPoint);
