import React, {useMemo} from 'react';
import {selectDevicesByName, useGetDevicesQuery} from "../../redux/services/express";
import PaginatedList from "../paginated-list";
import useTablePagination from "../../utils/useTablePagination";
import {FormInstance, Input, Space, Typography} from "antd";
import {useTypedSelector} from "../../redux/store";
import {CheckboxProps} from "antd/es/checkbox";
import {NamePath} from "rc-field-form/lib/interface";
import {EResource} from "../../contracts/EResource";
import {RowClassName} from "rc-table/lib/interface";

export type RecordCheckbox = Partial<Omit<CheckboxProps, 'checked' | 'defaultChecked'>>;

export interface IDevicePickerProps {
    name: NamePath;
    form: FormInstance<any>;
    setAlertSetupError?:any;
    getCheckboxProps?: (record: any) => RecordCheckbox;
    rowClassName?: string | RowClassName<any>;
}

export const DevicePicker: React.FC<IDevicePickerProps> = ({name, form, getCheckboxProps, rowClassName, setAlertSetupError}) => {
    const {
        isLoading: loadingDeviceList,
    } = useGetDevicesQuery(undefined);

    const {
        pagination,
        searchTerm,
        handleSearch,
        handleTableChange,
    } = useTablePagination({
        pageSize: 5,
    });

    const deviceList = useTypedSelector((state) => selectDevicesByName(state, searchTerm));

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: "IMEI",
            dataIndex: 'mobileId',
        }
    ];

    const selectionSummary = (
        <>
            <Typography.Text type="secondary">
                Selected {form.getFieldValue(name)?.length} of {deviceList.length} devices
            </Typography.Text>
        </>
    );

    return (
        <Space direction='vertical' size={12} style={{width: '100%'}}>
            <Input.Search
                onSearch={handleSearch}
                defaultValue={searchTerm}
                allowClear
            />
            <div>
                {
                    form.getFieldError(name)?.map((error) => (
                        <div key={error} style={{color: 'red'}}>
                            {error}
                        </div>
                    ))
                }
            </div>

            <PaginatedList
                controlPageTitle={false}
                controlHeader={false}
                title="Devices"
                resource={EResource.DEVICES}
                columns={columns}
                dataSource={deviceList}
                onChange={handleTableChange}
                isLoading={loadingDeviceList}
                isCompact={true}
                pagination={pagination}
                rowClassName={rowClassName}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: form.getFieldValue(name),
                    getCheckboxProps: (record) => {
                        if (getCheckboxProps) {
                            return getCheckboxProps(record);
                        }
                        return {
                            disabled: false
                        };
                    },
                    onChange: (selectedRowKeys, selectedRows, info) => {
                        if (info?.type === 'all') {
                            if (form.getFieldValue(name)?.length === deviceList.length) {
                                form.setFieldValue(name, []);
                            } else {
                                form.setFieldValue(name, deviceList.map((row) => row.id));
                            }
                            setAlertSetupError("")
                        } else {
                            form.setFieldValue(name, selectedRows.map((row) => row.id));
                            setAlertSetupError("")
                        }
                    },
                }}
                afterContent={selectionSummary}
            />
        </Space>
    );
}
