import React from 'react';
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

interface IBooleanIconProps {
    value: boolean | string;
}

export const BooleanIcon: React.FC<IBooleanIconProps> = ({value}) =>
    (value && value === true) || value === 'true' ? (
        <CheckOutlined
            style={{
                fontSize: '10px',
                background: '#9CE8D7',
                borderRadius: '50%',
                padding: '8px',
            }}
        />
    ) : (
        <CloseOutlined
            style={{
                fontSize: '10px',
                background: '#FFC3C5',
                borderRadius: '50%',
                padding: '8px',
            }}
        />
    )
