import {Popconfirm, Tooltip, Typography} from "antd";
import {ColumnsType} from "antd/es/table";

import iconDelete from "assets/svg/icon-delete.svg";
import iconEdit from "assets/svg/icon-edit.svg";
import {TableProps as RcTableProps} from "rc-table/lib/Table";
import React from "react";
import {EPermission} from "../../contracts/EPermission";
import {EResource} from "../../contracts/EResource";
import AccessControl from "../core/AccessControl";
import "./table-grid.scss"


const {Text} = Typography;

interface IGridConfig {
    title: (item: any) => React.ReactNode;

    [key: string]: (item: any) => React.ReactNode | Function | void | any;
}

interface ITableGridProps<RecordType> {
    title: string;
    columns?: ColumnsType<RecordType>;
    dataSource?: RcTableProps<RecordType>['data'];
    gridConfig?: IGridConfig;
    resource: EResource;
}

const TableGrid: React.FC<ITableGridProps<any>> = ({resource, gridConfig, dataSource, title, columns}) => {
    const shouldShowDelete = gridConfig?.onDelete;
    const shouldShowEdit = gridConfig?.onEdit;

    return (
        <div className="grid-view">
            <div className="grid-view-inner">
                {dataSource?.map((item, i) => (<div key={i} className="setting-grid">
                    <div className="dashboard-tile-header">
                        <h3>
                            <Text ellipsis={{
                                tooltip: true,
                            }}>{gridConfig?.title(item)}</Text>
                        </h3>
                        <div className="grid-header-right">
                            <ul>
                                {
                                    shouldShowDelete &&
                                    <AccessControl
                                        resource={resource}
                                        permissionNeeded={EPermission.DELETE}
                                        render={() => (
                                            <li>
                                                <Popconfirm
                                                    title="Delete this record?"
                                                    onConfirm={() => gridConfig.onDelete(item.id)}
                                                    onCancel={gridConfig.onCancel}
                                                    okText="Yes"
                                                    cancelText="NO"
                                                >
                                                    <Tooltip title={`Delete ${title}`}>
                                                        <div
                                                            style={{
                                                                float: "left",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <img src={iconDelete} alt="deleteIconRuleIcon"/>
                                                        </div>
                                                    </Tooltip>
                                                </Popconfirm>
                                            </li>
                                        )}
                                    />
                                }
                                {
                                    shouldShowEdit &&
                                    <AccessControl
                                        resource={resource}
                                        permissionNeeded={EPermission.UPDATE}
                                        render={() => (
                                            <li>
                                                <Tooltip title={`Edit ${title}`}>
                                                    <div style={{float: "left", cursor: "pointer"}}>
                                                        <img
                                                            src={iconEdit}
                                                            alt="editAlertRuleIcon"
                                                            onClick={() => gridConfig.onEdit(item)}
                                                            style={{
                                                                padding: "2px",
                                                                background: "#000",
                                                                borderRadius: " 50%",
                                                            }}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </li>
                                        )}/>
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="grid-content">
                        {gridConfig.gridContent(item)}
                    </div>
                </div>))}
            </div>
        </div>

    );
}

export default TableGrid;
