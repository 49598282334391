import {
    Badge,
    Button,
    Col,
    Input,
    Layout,
    Popover,
    Row,
    Space,
    Spin,
    Table,
    TablePaginationConfig,
    theme,
    Typography
} from "antd";
import {FormInstance} from "antd/es/form";
import {FilterValue, SorterResult, TableCurrentDataSource, TableRowSelection} from "antd/es/table/interface";


import iconGridView from "assets/svg/icon-grid-view.svg";
import iconListView from "assets/svg/icon-list-view.svg";


import TableGrid from 'components/table-grid';
import {RowClassName} from "rc-table/lib/interface";
import {TableProps as RcTableProps} from "rc-table/lib/Table";
import React, {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Helmet from 'react-helmet';
import {EPermission} from "../../contracts/EPermission";
import {EResource} from "../../contracts/EResource";
import {exportToPdf} from "../../utils/exportToPdf";
import {exportToXlsx} from "../../utils/exportToXlsx";
import useToggleValue from "../../utils/useToggleValue";
import AccessControl from "../core/AccessControl";
import {EditableCell, EditableRow} from "./EditableCell";

import './paginated-list.scss';


const {Title} = Typography;
const {Header, Content, Footer} = Layout;

export type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
export type EditableColumn = {
    dataIndex: string;
    editable?: boolean;
    column?: boolean;
    handleSave?: (record: unknown) => void;
    component?: (form: FormInstance<any>, onSave: () => void) => React.ReactNode;
    action?: (form: FormInstance<any>, onSave: () => void) => React.ReactNode;

    exportable?: boolean;
    renderData?: (item: unknown, record: unknown) => string;

    isGridHeader?: boolean;
    isGridContent?: boolean;
};
export type HolotrakColumnConfig = (ColumnTypes[number] & EditableColumn)[];

export type PrepareExport<RecordType> = (data: RcTableProps<RecordType>['data']) => Promise<RcTableProps<RecordType>['data']>;

interface IPaginatedListProps<RecordType> {
    title: string;
    subTitle?: string;
    onSearch?: (value: string, event?: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>) => void;
    onChange?: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<RecordType> | SorterResult<RecordType>[], extra: TableCurrentDataSource<RecordType>) => void;

    dataSource?: RcTableProps<RecordType>['data'];
    prepareExport?: PrepareExport<RecordType>;

    showTotal?: boolean;
    totalCount?: number;
    pagination?: false | TablePaginationConfig;

    isLoading?: boolean;

    tableClassName?: string;

    columns?: HolotrakColumnConfig;
    rowSelection?: TableRowSelection<RecordType>;
    rowClassName?: string | RowClassName<RecordType>;


    allowExport?: boolean;
    controlPageTitle?: boolean;
    controlHeader?: boolean;
    hasGridView?: boolean;

    beforeExportSlot?: React.ReactNode;
    additionalHeader?: React.ReactNode;
    beforeContent?: React.ReactNode;
    afterContent?: React.ReactNode;

    resource: EResource;

    gridConfig?: IGridConfig;

    isCompact?: boolean;
}

interface IGridConfig {
    title: (item: any) => React.ReactNode;

    [key: string]: (item: any) => React.ReactNode | Function | any;
}

const PaginatedList: React.FC<IPaginatedListProps<any>> = (props) => {
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const headerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);

    const [usedHeight, setUsedHeight] = useState(0);
    const [isGridView, toggleGridView] = useToggleValue(false);
    const [isTryingToExport, toggleIsTryingToExport] = useToggleValue(false);


    useEffect(() => {
        if (headerRef.current || footerRef.current) {
            setUsedHeight((headerRef.current?.clientHeight ?? 0) + (footerRef.current?.clientHeight ?? 0));
        }
    }, []);

    const allowExport = props.allowExport ?? true;
    const showHelmetTitle = props.controlPageTitle ?? true;
    const showHeader = props.controlHeader ?? true;
    const hasGridView = props.hasGridView ?? false;
    const showTotal = props.showTotal ?? false;

    const exportExcel = useCallback(() => {
        if (props.prepareExport === undefined) {
            return exportToXlsx(
                'holotrak',
                props.title,
                props.dataSource,
                props.columns,
            );
        } else {
            props.prepareExport(props.dataSource).then((data) => {
                return exportToXlsx(
                    'holotrak',
                    props.title,
                    data,
                    props.columns,
                );
            });
        }
    }, [props]);


    const exportPDF = useCallback(() => {
        if (props.prepareExport === undefined) {
            return exportToPdf(
                'holotrak',
                props.title,
                props.dataSource,
                props.columns,
            );
        } else {
            props.prepareExport(props.dataSource).then((data) => {
                return exportToPdf(
                    'holotrak',
                    props.title,
                    data,
                    props.columns,
                );
            });
        }
    }, [props]);

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    };

    const contentStyle = useMemo<CSSProperties>(() => {
        if (props.isCompact === undefined || props.isCompact === false) return ({
            height: `calc(100vh - ${usedHeight + 8}px)`,
            overflowY: 'auto'
        });

        return {};
    }, [props.isCompact, usedHeight]);
    return (
        <>
            {
                showHelmetTitle
                && (
                    <Helmet>
                        <title>{props.title}</title>
                        <meta name="description"
                              content={props.subTitle ? props.subTitle : `Holotrak List ${props.title}`}/>
                    </Helmet>
                )
            }


            <AccessControl
                resource={props.resource}
                permissionNeeded={EPermission.READ}
                render={() => (
                    <>

                        {
                            showHeader
                            && (
                                <Header className='holotrak-page-header' style={{background: colorBgContainer}}
                                        ref={headerRef}>
                                    <Title className='holotrak-page-title' level={3}>{props.title}</Title>

                                    {
                                        showTotal
                                            ? <Badge overflowCount={props.totalCount} count={props.totalCount}
                                                     style={{
                                                         backgroundColor: "#dfe3ef",
                                                         color: "#000",
                                                         borderRadius: "18px",
                                                         height: "22px",
                                                         paddingTop: "2px",
                                                         paddingInline: "14px",
                                                     }}/>
                                            : null
                                    }


                                    {props.beforeExportSlot}
                                    {
                                        allowExport
                                        &&
                                        <>
                                            <Popover
                                                content={(
                                                    <Space>
                                                        <Button
                                                            block
                                                            onClick={exportExcel}
                                                            className='export-activity'
                                                        >
                                                            Excel
                                                        </Button>

                                                        <Button
                                                            block
                                                            onClick={exportPDF}
                                                            className='export-activity'
                                                        >
                                                            PDF
                                                        </Button>
                                                    </Space>
                                                )}
                                                title="Export Data"
                                                trigger="click"
                                                open={isTryingToExport}
                                                onOpenChange={toggleIsTryingToExport}
                                            >
                                                <Button
                                                    size='small'
                                                    shape='round'
                                                    className='export-activity export-activity--container'
                                                >
                                                    Export
                                                </Button>
                                            </Popover>

                                        </>
                                    }

                                    {
                                        hasGridView && (
                                            <Button
                                                className='holotrak-table-grid-action-wrapper'
                                                title='Toggle Grid View'
                                                shape='circle'
                                                type='default'
                                                onClick={toggleGridView}
                                            >
                                                <img src={isGridView ? iconListView : iconGridView} alt="Icon Grid View"/>
                                            </Button>
                                        )}
                                    <div className='holotrak-table--search-controls search-device-activity'>
                                        <Input.Search placeholder='Quick Search' onSearch={props.onSearch} allowClear/>
                                        {props.additionalHeader}
                                    </div>
                                </Header>
                            )
                        }
                        <Content style={contentStyle} className={'holotrak-paginated-list--content'}>
                            {props.beforeContent}
                            <Row>
                                <Col span={24}>
                                    <Spin spinning={props.isLoading && !props.dataSource}>
                                        {
                                            props.dataSource
                                            &&
                                            <>
                                                {isGridView
                                                    ? (
                                                        <TableGrid
                                                            resource={props.resource}
                                                            dataSource={props.dataSource}
                                                            columns={props.columns}
                                                            gridConfig={props.gridConfig}
                                                            title={props.title}
                                                        />
                                                    )
                                                    : <Table
                                                        rowKey='id'
                                                        className={`holotrak-table ${props.tableClassName ? props.tableClassName : ""}`.trim()}
                                                        rowClassName={
                                                            props.rowClassName ? props.rowClassName : () => "editable-row"
                                                        }
                                                        dataSource={props.dataSource}
                                                        components={components}
                                                        rowSelection={props.rowSelection}
                                                        columns={props.columns.filter((column: any) => {
                                                            if (column.column === undefined || column.column === null) {
                                                                column.column = true;
                                                            }
                                                            return column.column;
                                                        }).map((col) => {
                                                            if (!col.editable) {
                                                                return col;
                                                            }
                                                            return {
                                                                ...col,
                                                                onCell: (record: any) => ({
                                                                    record,
                                                                    editable: col.editable,
                                                                    dataIndex: col.dataIndex,
                                                                    title: col.title,
                                                                    handleSave: col.handleSave,
                                                                    component: col.component,
                                                                    action: col.action,
                                                                }),
                                                            };
                                                        }) as ColumnTypes}
                                                        loading={props.isLoading}
                                                        pagination={{
                                                            total: props.totalCount,
                                                            showSizeChanger: false,
                                                            showQuickJumper: false,
                                                            showTotal: (total) => `Total ${total} items`,
                                                            ...props.pagination
                                                        }}
                                                        onChange={props.onChange}
                                                    />}
                                            </>
                                        }
                                    </Spin>
                                </Col>
                            </Row>
                        </Content>
                        <Footer ref={footerRef}
                                className={`footer-paginated-list footer-paginated-list--${props.title.toLowerCase()}`}>
                            {props.afterContent}
                        </Footer>
                    </>
                )}
            />
        </>
    );
}

export default PaginatedList;
