import React, {useMemo, useState} from 'react';
import PaginatedList from "../../components/paginated-list";
import {Button, Space, Typography} from "antd";
import {useGetIndoorAssetExpiryReportQuery} from "../../redux/services/express/reports";
import {IconFilter} from "../../assets/icons/icon-filter";
import useTablePagination from "../../utils/useTablePagination";
import useToggleValue from "../../utils/useToggleValue";
import {useNavigate} from "react-router-dom";
import {EGroupType, getGroupTypeName, getRouteForGroupType} from "../../contracts/EGroupType";
import ColumnConfigurator from "../../components/paginated-list/ColumnConfigurator";
import {DateTimeRenderer} from "../../components/datetime";
import {IndoorAssetSerializer} from "../../contracts/holotrak/IndoorAsset";
import {EResource} from "../../contracts/EResource";

const {Text} = Typography;

export const IndoorAssetsReport: React.FC = () => {
    const {
        searchTerm,
        handleSearch,
        pagination,
        handleTableChange,
    } = useTablePagination({
        pageSize: 7
    });

    const navigate = useNavigate();

    const [columns, setColumns] = useState([
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            column: true,
            sorter: true,
        },
        {
            title: 'Layout',
            dataIndex: 'layoutId',
            key: 'layoutId',
            column: true,
            render: (item, record) => record.getTagValue('layoutId')
        },
        {
            title: 'Class',
            dataIndex: 'deviceClass',
            key: 'deviceClass',
            column: true,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            column: true,
            render: (item, record) => record.getTagValue('type')
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            column: true,
            render: value => value || '',
        },
        {
            title: 'Last known Location',
            dataIndex: 'additionalInfo',
            key: 'additionalInfo',
            column: true,
            render: value => (value === '--' ? '' : value || ''),
        },
        {
            title: 'Creation Date',
            dataIndex: 'createdAt',
            key: 'creationDate',
            sorter: true,
            render: (item) => <DateTimeRenderer date={item}/>,
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
            column: true,
            sorter: true,
            render: (item) => <DateTimeRenderer date={item}/>,
        },
    ]);

    const {isLoading, data: tableResponse} = useGetIndoorAssetExpiryReportQuery(null, {
        selectFromResult: ({isLoading, data}) => {
            return {
                data: {
                    totalCount: data?.totalCount ?? 0,
                    items: data?.items?.map((item) => IndoorAssetSerializer.parse(item)) || [],
                },
                isLoading
            };
        },
    });

    const records = useMemo(() => {
        return isLoading ? [] : tableResponse.items.filter((item) => {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [isLoading, searchTerm, tableResponse.items]);


    const [isFilterOpen, toggleFilterOpen] = useToggleValue(false);
    const filterSupport = useMemo(() => {
        return (
            <div className="filter-support">
                <Button type='default' shape='circle' icon={<IconFilter/>} onClick={toggleFilterOpen}/>
            </div>
        );
    }, [toggleFilterOpen]);

    return (
        <>
            <PaginatedList
                title="Indoor Assets"
                resource={EResource.INDOOR_ASSETS}
                onSearch={handleSearch}
                pagination={pagination}
                onChange={handleTableChange}
                isLoading={isLoading}
                dataSource={records}
                columns={columns}
                additionalHeader={filterSupport}
                totalCount={tableResponse?.totalCount}
                showTotal={true}
                afterContent={(
                    <div className='bottom-bar'>
                        <Space size={24}>
                            <Button
                                size='small'
                                onClick={() => {
                                    navigate(`/${getRouteForGroupType(EGroupType.INDOOR_ASSET)}`);
                                }}
                            >
                                {getGroupTypeName(EGroupType.INDOOR_ASSET)}
                            </Button>
                        </Space>
                    </div>
                )}
            />
            <ColumnConfigurator
                isConfiguring={isFilterOpen}
                toggleConfigurationMode={toggleFilterOpen}
                columns={columns}
                setColumns={setColumns}
            />
        </>
    );
};

export default IndoorAssetsReport;
