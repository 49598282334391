import {losantApi} from "./index";
import {Device, DeviceReportResponse, DeviceSerializer} from "../../../contracts/holotrak/device";
import {devicesAdapter, expressApi} from "../express";
import {TAG_DEVICE, TAG_INDOOR_ASSET, TAG_INDOOR_LAYOUT} from "../../../contracts/reduxResourceTags";
import {ILosantListQuery} from "../../../contracts/ILosantListQuery";
import {IndoorAsset} from "../../../contracts/holotrak/IndoorAsset";
import {indoorAssetsApi} from "../express/indoor-assets";
import {IItemNameUpdateRequest} from "../../../contracts/IItemNameUpdateRequest";
import {IDeviceMessageResponse} from "../../../contracts/IDeviceMessageResponse";
import {IDeviceMessageRequest} from "../../../contracts/IDeviceMessageRequest";

const devicesApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        sendDeviceMessage: builder.mutation<IDeviceMessageResponse, IDeviceMessageRequest>({
            invalidatesTags: [TAG_DEVICE],
            query: (deviceMessageRequest) => {
                return {
                    url: `/device/sms-command`,
                    method: "post",
                    body: deviceMessageRequest,
                };
            }
        }),
        getDeviceDetails: builder.query<Device, string>({
            providesTags: (deviceFromApi) => {
                return [
                    {type: TAG_DEVICE, id: deviceFromApi.id},
                ];
            },
            query: (deviceId) => {
                return {
                    url: `/device/${deviceId}`,
                    method: "get",
                };
            },
            transformResponse: (response: Device[]) => {
                return response[0];
            },
            async onQueryStarted(deviceId, {dispatch, queryFulfilled}) {
                // update the list of devices as well in expressApi
                const {data: device} = await queryFulfilled;

                dispatch(
                    expressApi.util.updateQueryData("getDevices", undefined, (draft) => {
                        devicesAdapter.upsertOne(draft, DeviceSerializer.parse({...device}));
                    })
                );
            },
        }),
        getIndoorAssetDetails: builder.query<IndoorAsset, string>({
            query: (deviceId) => {
                return {
                    url: `/device/${deviceId}`,
                    method: "get",
                };
            },
            transformResponse: (response: IndoorAsset[]) => {
                return response[0];
            },
            async onQueryStarted(deviceId, {dispatch, queryFulfilled}) {
                // update the list of devices as well in expressApi
                const {data: indoorAsset} = await queryFulfilled;

                dispatch(
                    indoorAssetsApi.util.updateQueryData('getIndoorAssets', undefined, (draft) => {
                        // FUTURE: should allow for adapter to be passed in
                        // indoorAssetsAdapter.upsertOne(draft, IndoorAssetSerializer.parse({...indoorAsset}));
                    })
                );
            },
        }),
        getDeviceReport: builder.query<DeviceReportResponse, ILosantListQuery>({
            query: (queryArgs) => {
                const paginationQuery = `${queryArgs.page}&pageSize=${queryArgs.limit}`;
                const searchQuery = (queryArgs.searchValue) ? `&filterField=name&filterValue=${queryArgs.searchValue}` : '';
                const sortQuery = (queryArgs.sort && queryArgs.sortBy) ? `&sortField=${queryArgs.sortBy}&sortDirection=${queryArgs.sort === 'ascend' ? 'asc' : 'desc'}` : '';

                return {
                    url: `/list/device?page=${paginationQuery}${searchQuery}${sortQuery}`,
                    method: "get",
                }
            }
        }),
        updateDeviceName: builder.mutation<Device, IItemNameUpdateRequest>({
            invalidatesTags: (response, error, request) => [{type: TAG_DEVICE, id: request.id}],
            query: (deviceRequest) => {
                return {
                    url: `/device/update-name/${deviceRequest.id}`,
                    method: "put",
                    body: {
                        deviceName: deviceRequest.name,
                    },
                }
            }
        }),
        updateDeviceTag: builder.mutation<Device, IDeviceTagRequest>({
            invalidatesTags: (response, error, request) => {
                return [
                    TAG_INDOOR_LAYOUT,
                    {type: TAG_DEVICE, id: request.deviceId},
                    {type: TAG_INDOOR_ASSET, id: 'LIST'}
                ];
            },
            query: (deviceRequest) => {
                return {
                    url: `/device/update-tag/${deviceRequest.deviceId}`,
                    method: "put",
                    body: {
                        tag: deviceRequest.tag,
                        value: deviceRequest.value,
                    },
                }
            }
        }),
    })
});

export const {
    useGetDeviceDetailsQuery,
    useSendDeviceMessageMutation,
    useUpdateDeviceNameMutation,
    useUpdateDeviceTagMutation,
    useGetDeviceReportQuery,
    useGetIndoorAssetDetailsQuery,
} = devicesApi;


interface IDeviceTagRequest {
    deviceId: string;
    tag: string;
    value: string;
}
