import {ConfigProvider} from 'antd';

import 'antd/dist/reset.css';
import {ThemeConfig} from "antd/es/config-provider/context";
import React, {useMemo} from 'react';

import {Helmet} from 'react-helmet';
import {Provider} from "react-redux";
import store from "redux/store";
import "styles/global.scss";
import {HolotrakApp} from "./HolotrakApp";

// COLORS
export const COLOR_ONLINE = '#2AC19F';
export const COLOR_ONLINE_DARK = '#2E7A69';

export const COLOR_OFFLINE = '#F9979A';
export const COLOR_OFFLINE_DARK = '#DA7178';

export const COLOR_PRIMARY = '#2AC19F';
export const COLOR_DANGER = '#F88387';
export const COLOR_INFO = '#48C0FF';
export const COLOR_MUTED = '#909FAF';
export const COLOR_WHITE = '#f9f9f9';
export const COLOR_WARNING = '#ffb100';
export const COLOR_DARK = "#000000";
export const COLOR_LIGHT = "#ffffff";


function App(): JSX.Element {
    const themeConfig: ThemeConfig = useMemo<ThemeConfig>(() => {
        const themeConfig: ThemeConfig = {
            token: {
                "colorPrimary": COLOR_PRIMARY,
                "colorSuccess": COLOR_PRIMARY,
                "colorWarning": COLOR_WARNING,
                "colorError": COLOR_DANGER,
                "colorInfo": COLOR_INFO,
                "colorBgBase": COLOR_WHITE,
                "colorBgContainer": COLOR_WHITE,
                "borderRadius": 16,
                "fontFamily": "'SFProDisplay', sans-serif",
                "fontSize": 12,
            },
            components: {
                "Input": {
                    "borderRadius": 16,
                },
                "Button": {
                    "borderRadius": 16,
                    "borderRadiusSM": 16,
                    "colorBgContainer": COLOR_LIGHT,
                    "colorBgTextHover": COLOR_PRIMARY,
                },
                "Layout": {
                    "colorBgContainer": COLOR_DARK,
                    "colorBgHeader": COLOR_WHITE,
                },
                "Menu": {
                    "itemBg": COLOR_DARK,
                    "itemColor": COLOR_LIGHT,
                    "itemHoverBg": COLOR_DARK,
                    "itemHoverColor": COLOR_PRIMARY,
                    "itemSelectedBg": COLOR_DARK,
                }
            }
        };

        return themeConfig;
    }, []);

    return (
        <ConfigProvider
            theme={themeConfig}
        >
            <Provider store={store}>
                <Helmet titleTemplate="%s | Holotrak"/>
                <HolotrakApp/>
            </Provider>
        </ConfigProvider>
    );
}


export default App;
