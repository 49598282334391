import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {EventData} from "./eventData";
import {EventTags} from "./eventTags";
import {EventUpdate} from "./eventUpdate";
import dayjs from "dayjs";

@jsonObject()
export class DeviceEvent {
    @jsonMember(String)
    subject: string;
    @jsonMember(String)
    message: string;
    @jsonMember(EventData)
    data: EventData;
    @jsonMember(String)
    deviceId: string;
    @jsonMember(EventTags)
    eventTags: EventTags;
    @jsonMember(String)
    sourceType: string;
    @jsonMember(String)
    sourceId: string;
    @jsonMember(String)
    applicationId: string;
    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    creationDate: dayjs.Dayjs;
    @jsonMember(String)
    lastUpdated: string;
    @jsonArrayMember(EventUpdate)
    updates: EventUpdate[];
    @jsonMember(String)
    eventId: string;
    @jsonMember(String)
    id: string;
    @jsonMember(String)
    level: string;
    @jsonMember(String)
    state: string;
    @jsonMember(String)
    deviceName: string;
    @jsonMember(String)
    sourceName: string;
}

export const deviceEventSerializer = new TypedJSON(DeviceEvent);
