import {jsonArrayMember, jsonMember, jsonObject} from "typedjson";

@jsonObject
export class UserTags {
    @jsonMember(String)
    isSuper: boolean | string;

    @jsonMember(Boolean, {
        deserializer: (value: any) => {
            return value === "true";
        }
    })
    canPerformDebugging: boolean;

    @jsonMember(String)
    status: string;

    @jsonMember(String)
    organizationId?: string;

    @jsonArrayMember(String, {
        deserializer: (value: any) => {
            return value ? JSON.parse(value) : [];
        },
    })
    roles?: string[];

    @jsonMember(String)
    sendResetEmail: string;

    @jsonMember(String)
    phoneNumber?: string;

    @jsonMember(String)
    mobileNumber?: string;

    @jsonMember(String)
    email?: string;

    @jsonMember(String)
    userProfileImage?: string;
}
