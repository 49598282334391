import React, {useCallback, useMemo, useState} from 'react';
import PaginatedList from "../../components/paginated-list";
import {Button, Form, message, Popconfirm, Space, Tooltip} from "antd";
import {IconFilter} from "../../assets/icons/icon-filter";
import useTablePagination from "../../utils/useTablePagination";
import useToggleValue from "../../utils/useToggleValue";
import {useNavigate} from "react-router-dom";
import {EGroupType, getRouteForGroupType} from "../../contracts/EGroupType";
import ColumnConfigurator from "../../components/paginated-list/ColumnConfigurator";
import {EResource} from "../../contracts/EResource";
import {
    useDeleteDriverMutation,
    useGetPaginatedDriversListQuery,
    useUpdateDriverMutation
} from "../../redux/services/losant/drivers";
import {Driver, DriverSerializer} from "../../contracts/holotrak/driver";
import {DateTimeRenderer} from "../../components/datetime";
import AccessControl from "../../components/core/AccessControl";
import {EPermission} from "../../contracts/EPermission";
import iconDelete from "../../assets/svg/icon-delete.svg";
import iconEdit from "../../assets/svg/icon-edit.svg";
import DriverForm from "../../components/driver-form";
import {ClearOutlined} from "@ant-design/icons";
import {Vehicle} from "../../contracts/holotrak/vehicle";
import VehiclesDropdown from "../../components/dropdowns/VehiclesDropdown";
import {VehicleBase} from "../../contracts/holotrak/vehicleBase";


export const DriverReport: React.FC = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [isDriverFormOpen, setIsDriverFormOpen] = useState<boolean>(false);
    const [currentDriver, setCurrentDriver] = useState<Driver>(null);
    const {
        searchTerm,
        handleSearch,
        sorting,
        pagination,
        handleTableChange,
    } = useTablePagination({
        pageSize: 30
    });

    const [updateDriver] = useUpdateDriverMutation();

    const cancelDelete = useCallback(() => {
        return messageApi.open({
            type: "error",
            content: "Cancelled",
        });
    }, [messageApi]);

    const navigate = useNavigate();
    const [deleteDriver] = useDeleteDriverMutation();

    const handleDriverDeleteClicked = useCallback((driverId: string) => {
        deleteDriver(driverId);
    }, [deleteDriver]);

    const addNewDriver = useCallback(() => {
        setCurrentDriver(null);
        setIsDriverFormOpen(true);
    }, [setCurrentDriver, setIsDriverFormOpen]);


    const [columns, setColumns] = useState([
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            column: true,
            sorter: true,
        },
        {
            title: 'Vehicle',
            dataIndex: 'vehicleId',
            key: 'vehicle',
            width: 200,
            column: true,
            render: (item, record) => record.vehicle?.name || '',
            editable: true,
            action: (form, saveForm) => (
                <>
                    <Tooltip title={'Un assign'}>
                        <Button icon={<ClearOutlined/>} onClick={() => {
                            form.setFieldsValue({vehicleId: null});
                            saveForm();
                        }}/>
                    </Tooltip>
                </>
            ),
            component: (form, saveForm) => (
                <Form.Item
                    dependencies={['vehicleId']}
                >
                    {
                        (form) => (
                            <VehiclesDropdown
                                fieldName={['vehicleId']}
                                onBlur={saveForm}
                            />
                        )
                    }
                </Form.Item>
            ),
            handleSave: async (row: Driver) => {
                let vehicle = row.vehicle ?? new VehicleBase();
                if (vehicle.id !== row.vehicleId) {
                    vehicle = new VehicleBase();
                    vehicle.id = row.vehicleId;
                    updateDriver({
                        data: {
                            ...row,
                            vehicle: vehicle
                        }
                    }).then(() => {
                        messageApi.success('Driver updated successfully');
                    });
                }
            }
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            column: true,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            column: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            column: true,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            column: true,
        },
        {
            title: 'Issuing Authority',
            dataIndex: 'issuingAuthority',
            key: 'issuingAuthority',
            column: false,
        },
        {
            title: 'License Number',
            dataIndex: 'driverLicenceNumber',
            key: 'driverLicenceNumber',
            column: true,
        },
        {
            title: 'License Class',
            dataIndex: 'licenceClass',
            key: 'licenceClass',
            column: false,
        },
        {
            title: 'Expiration Date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            column: false,
            render: (item, record) => item ? <DateTimeRenderer date={item}/> : '-',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            column: true,
            render: (item, record) => item ? <DateTimeRenderer date={item}/> : '-',
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            column: false,
            render: (item, record) => item ? <DateTimeRenderer date={item}/> : '-',
        },
        {
            title: "ACTIONS",
            dataIndex: "actions",
            key: "actions",
            render: (item, record: Driver) => (
                <Space>
                    <AccessControl
                        resource={EResource.DRIVERS}
                        permissionNeeded={EPermission.DELETE}
                        sidebar={true}
                        render={
                            accessProps => (
                                <Popconfirm
                                    {...accessProps}
                                    title="Delete this record?"
                                    onConfirm={() => handleDriverDeleteClicked(record.id)}
                                    onCancel={cancelDelete}
                                    okText="Yes"
                                    cancelText="NO"
                                >
                                    <Tooltip title="Delete Driver">
                                        <div style={{float: "left", cursor: "pointer"}}>
                                            <img src={iconDelete} alt="DeleteGroupIcon"/>
                                        </div>
                                    </Tooltip>
                                </Popconfirm>
                            )
                        }/>

                    <AccessControl
                        resource={EResource.DRIVERS}
                        permissionNeeded={EPermission.UPDATE}
                        render={() => (
                            <Tooltip title="Edit Driver">
                                <div style={{float: "left", cursor: "pointer"}}>
                                    <img
                                        src={iconEdit}
                                        alt="EditGroupIcon"
                                        onClick={() => {
                                            setCurrentDriver(record);
                                            setIsDriverFormOpen(true);
                                        }}/>
                                </div>
                            </Tooltip>
                        )}
                    />
                </Space>
            ),
        },

    ]);

    const {isLoading, data: tableResponse} = useGetPaginatedDriversListQuery({
        page: pagination.current - 1,
        limit: pagination.pageSize,
        fieldValue: 'name',
        sort: sorting.order,
        sortBy: sorting.field,
        searchValue: searchTerm,
    }, {
        selectFromResult: ({isLoading, data}) => {
            return {
                data: {
                    totalCount: data?.totalCount ?? 0,
                    items: data?.items?.map((item) => DriverSerializer.parse(item)) || [],
                },
                isLoading
            };
        },
    });

    const records = useMemo(() => {
        return isLoading ? [] : tableResponse.items;
    }, [isLoading, tableResponse.items]);


    const [isFilterOpen, toggleFilterOpen] = useToggleValue(false);
    const filterSupport = useMemo(() => {
        return (
            <div className="filter-support">
                <Button type='default' shape='circle' icon={<IconFilter/>} onClick={toggleFilterOpen}/>
            </div>
        );
    }, [toggleFilterOpen]);

    const closeDriverForm = useCallback(() => {
        setIsDriverFormOpen(false);
        setCurrentDriver(null);
    }, [setIsDriverFormOpen, setCurrentDriver]);

    const renderGridContent = useCallback((item: Driver) => {
        return !item ? null : (
            <ul>
                <li>
                    <h4>Vehicle</h4>
                    <p>{item.vehicle?.name ?? '-'}</p>
                </li>
                <li>
                    <h4>Department</h4>
                    <p>{item.department ?? '-'}</p>
                </li>
                <li>
                    <h4>Phone</h4>
                    <p>{item.phone ?? '-'}</p>
                </li>
                <li>
                    <h4>Email</h4>
                    <p>{item.email ?? '-'}</p>
                </li>
                <li>
                    <h4>Location</h4>
                    <p>{item.location ?? '-'}</p>
                </li>
            </ul>
        );
    }, []);

    return (
        <>
            <PaginatedList
                title="All Drivers"
                resource={EResource.DRIVERS}
                onSearch={handleSearch}
                pagination={pagination}
                onChange={handleTableChange}
                isLoading={isLoading}
                dataSource={records}
                columns={columns}
                additionalHeader={filterSupport}
                totalCount={tableResponse?.totalCount}
                showTotal={true}
                beforeExportSlot={(
                    <Button shape='round' onClick={addNewDriver} className='add-new-driver'>
                        Add New Driver
                    </Button>
                )}

                afterContent={(
                    <div className='bottom-bar'>
                        <Space size={24}>
                            <Button
                                size='small'
                                onClick={() => {
                                    navigate(`/${getRouteForGroupType(EGroupType.DRIVER)}`);
                                }}
                            >
                                Map
                            </Button>
                        </Space>
                    </div>
                )}

                hasGridView={true}
                gridConfig={{
                    title: (item: Driver) => item?.name,
                    onDelete: (item: Driver) => handleDriverDeleteClicked(item.id),
                    onEdit: (item: Driver) => {
                        setCurrentDriver(item);
                        setIsDriverFormOpen(true);
                    },
                    onCancel: () => cancelDelete(),
                    gridContent: (item: Driver) => renderGridContent(item)
                }}

            />
            <ColumnConfigurator
                isConfiguring={isFilterOpen}
                toggleConfigurationMode={toggleFilterOpen}
                columns={columns}
                setColumns={setColumns}
            />

            <DriverForm
                isOpen={isDriverFormOpen}
                onClose={closeDriverForm}
                driver={currentDriver}
            />
        </>
    );
};

export default DriverReport;
