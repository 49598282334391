import {Button, Col, Drawer, FloatButton, Row, Spin} from "antd";
import React, {useCallback} from "react";
import {Helmet} from "react-helmet";
import {Link, Route, Routes} from "react-router-dom";
import {useAppDispatch, useTypedSelector} from "redux/store";
import {selectFilteredDrivers, useGetDriversQuery} from "redux/services/losant/drivers";

import {BottomBar} from "../../components/bottom-bar/BottomBar";
import {selectLeftDrawerOpen, toggleLeftDrawer} from "../../redux/slices/app";
import {IconArrowRightCircle} from "../../assets/icons/icon-arrow-right-circle";

const DriverList = React.lazy(() => import("./list"));
const DriverDetails = React.lazy(() => import("components/driver-details"));
const MapPane = React.lazy(() => import("components/map-pane"));
const GeofenceDrawer = React.lazy(() => import("components/geofence-management/drawer/GeofenceDrawer"));


export const Drivers: React.FC = () => {
    // Using a query hook automatically fetches data and returns query values
    const {isLoading: isLoadingDrivers, error: errorInLoadingVehicles} = useGetDriversQuery();
    const drivers = useTypedSelector(selectFilteredDrivers);
    const dispatch = useAppDispatch();

    const isLeftDrawerOpen = useTypedSelector(selectLeftDrawerOpen);
    const toggleDrawer = useCallback(() => {
        dispatch(toggleLeftDrawer());
    }, [dispatch]);

    const [bottomBarHeight, setBottomBarHeight] = React.useState(0);
    const bottomBarRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (bottomBarRef.current) {
            setBottomBarHeight(bottomBarRef.current.clientHeight);
        }
    }, [bottomBarRef]);

    return (
        <>
            <Helmet>
                <title>Drivers</title>
                <meta name="description" content="Holotrak Managed Drivers"/>
            </Helmet>
            <Row>
                <Col span={24}>
                    <FloatButton
                        className="left-drawer-toggle"
                        type='default'
                        shape="square"
                        onClick={toggleDrawer}
                        icon={<IconArrowRightCircle/>}
                    />
                    <Drawer
                        placement="left"
                        mask={false}
                        open={isLeftDrawerOpen}
                        closable={false}
                        getContainer={false}
                        motion={null}
                        bodyStyle={{padding: 0}}
                    >
                        <React.Suspense fallback={<Spin spinning={true}/>}>
                            <Routes>
                                <Route
                                    path="/"
                                    index
                                    element={
                                        <DriverList
                                            error={errorInLoadingVehicles}
                                            isLoading={isLoadingDrivers}
                                            items={drivers}
                                        />
                                    }
                                />
                                <Route path="/:id" element={<DriverDetails/>}/>
                            </Routes>
                        </React.Suspense>
                    </Drawer>

                    <React.Suspense fallback={<Spin spinning={true}/>}>
                        <MapPane
                            stillItems={drivers}
                            movingItems={[]}
                            consumedHeight={bottomBarHeight}
                        />
                        <GeofenceDrawer/>
                    </React.Suspense>

                    <Row ref={bottomBarRef}>
                        <Col span={24}>
                            <BottomBar>
                                <Link to={'/report/drivers'}>
                                    <Button size='small'>
                                        List
                                    </Button>
                                </Link>
                            </BottomBar>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default Drivers;
