import {jsonMember, jsonObject} from "typedjson";
import dayjs from "dayjs";


@jsonObject
export class UserRole {
    @jsonMember(String)
    name: string;
    @jsonMember(String)
    organisation: string;
    @jsonMember(String)
    devices: string;
    @jsonMember(String)
    drivers: string;
    @jsonMember(String)
    vehicles: string;
    @jsonMember(String)
    reports: string;
    @jsonMember(String)
    general: string;
    @jsonMember(String)
    groups: string;
    @jsonMember(String)
    deviceSettings: string;
    @jsonMember(String)
    geofence: string;
    @jsonMember(String)
    alerts: string;
    @jsonMember(String)
    billing: string;
    @jsonMember(String)
    dispatch: string;
    @jsonMember(String)
    route: string;
    @jsonMember(String)
    maintenance: string;
    @jsonMember(String)
    sensors: string;
    @jsonMember(String)
    trips: string;
    @jsonMember(String)
    tags: string;
    @jsonMember(String)
    kml: string;
    @jsonMember(String)
    description: string;
    @jsonMember(String)
    notifications: string;
    @jsonMember(String)
    messages: string;
    @jsonMember(String)
    activity: string;
    @jsonMember(String)
    events: string;
    @jsonMember(String)
    userPosition: string;
    @jsonMember(String)
    users: string;
    @jsonMember(String)
    roles: string;
    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;
    @jsonMember(String)
    id: string;
}
