import { Button, Form, Input, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLazyGetOrganisationQuery, useLoginRememberedUserMutation } from "redux/services/losant";
import { CardContainer } from "../../components/card-container";
import { redirect } from "react-router-dom";
import { useCreateActivityLogMutation } from 'redux/services/losant/activeLog';
import { useGeolocationInfo } from 'utils/useGeolocationInfo';
import { useTypedSelector } from 'redux/store';
import { selectCurrentUser } from 'redux/slices/auth';
import { ActivityLogWithLogin } from 'contracts/holotrak/authentication';

const { Text, Link } = Typography;

export const Login = () => {
    const [createActivityLog] = useCreateActivityLogMutation();
    const userIpInfo = useGeolocationInfo();
    // const user = useTypedSelector(selectCurrentUser);
    const [form] = Form.useForm();
    const [loginUser, { isLoading }] = useLoginRememberedUserMutation();

    const [fetchOrganisation] = useLazyGetOrganisationQuery();

    const onFinish = async ({ email, password }) => {
        try {
            const loginResult = loginUser({ email, password });
            loginResult.then(async (data: ActivityLogWithLogin) => {
                try {
                    await fetchOrganisation();
                    const activityLogData = {
                        ...userIpInfo,
                        userName: `${data?.data?.user?.firstName || ""} ${data?.data?.user?.lastName || ""}`.trim(),
                        user: String(data?.data?.user?.id || ""),
                        organisation: String(data?.data?.user?.userTags?.organizationId || ""),
                        actionType: 'sessionStart' as 'sessionStart'
                    };
    
                    await createActivityLog(activityLogData);
    
                    redirect('/dashboard');
                } catch (error) {
                    console.error("Error in fetching organisation or creating activity log", error);
                }
            }).catch(error => {
                console.error("Error in loginUser", error);
            });
        } catch (error) {
            console.error("Error in onFinish", error);
        }
    };
    
    
    return (
        <CardContainer>
            <Helmet>
                <title>Login</title>
                <meta name="description" content="Login to Holotrak Dashboard" />
            </Helmet>
            <Form
                form={form}
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                            type: 'email'
                        },
                    ]}
                >
                    <Input placeholder="Username or Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>

                <Button
                    loading={isLoading}
                    htmlType="submit"
                    type="primary"
                    className="main-btn"
                >
                    Sign In
                </Button>

                <Text className='password'>Can't remember your password?
                    <Link href='/forgot-password' className='recover'> Recover it.</Link>
                </Text>

            </Form>
        </CardContainer>
    );
}

export default Login;
