import React from 'react';
import type {TabsProps} from 'antd';
import {Form, Input, Tabs} from "antd";
import VehicleTypeSelector from "./VehicleTypeSelector";


interface IVechicleTabbedFormProps {
}

const VehicleTabbedForm: React.FC<IVechicleTabbedFormProps> = (props) => {

    const items: TabsProps['items'] = [
        {
            key: 'profile',
            label: `Profile`,
            children: (
                <>

                    <Form.Item
                        label="Vehicle Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please Input Vehicle Name!",
                            },
                        ]}
                    >
                        <Input placeholder="Input Vehicle Name"/>
                    </Form.Item>

                    <Form.Item label="Location" name="location">
                        <Input placeholder="Location for a Vehicle"/>
                    </Form.Item>

                    <Form.Item label="Make" name="make">
                        <Input placeholder="Make"/>
                    </Form.Item>

                    <Form.Item label="Model" name="model">
                        <Input placeholder="Model"/>
                    </Form.Item>

                    <Form.Item label="Year" name="year">
                        <Input placeholder="Year"/>
                    </Form.Item>

                    <Form.Item label="Color" name="color">
                        <Input placeholder="Color"/>
                    </Form.Item>

                    <Form.Item label="VIN" name="vin">
                        <Input type={"number"} placeholder="VIN"/>
                    </Form.Item>

                    <Form.Item label="Stock number" name="stockNumber">
                        <Input placeholder="Stock Number"/>
                    </Form.Item>

                    <Form.Item
                        label="Type"
                        dependencies={["type", "subType"]}
                    >
                        {
                            (form) => <VehicleTypeSelector typeField='type'/>
                        }
                    </Form.Item>

                    <Form.Item label="Insurance Policy Number" name="insurancePolicyNumber">
                        <Input placeholder="Insurance Policy Number"/>
                    </Form.Item>

                    <Form.Item label="Select Date" name="insurancePolicyDate">
                        <Input type={"date"} placeholder="Select Date"/>
                    </Form.Item>

                    <Form.Item label="Insurance 2 Policy Number" name="insurance2PolicyNumber">
                        <Input placeholder="Insurance 2 Policy Number"/>
                    </Form.Item>

                    <Form.Item label="Select Date" name="insurance2PolicyDate">
                        <Input type={"date"} placeholder="Select Date"/>
                    </Form.Item>

                    <Form.Item label="Tags" name="tags">
                        <Input placeholder="Tags"/>
                    </Form.Item>

                    <Form.Item label="Notes" name="notes">
                        <Input placeholder="Notes"/>
                    </Form.Item>

                </>
            ),
        },
        {
            key: 'specification',
            label: `Specification`,
            children: (
                <>

                    <Form.Item label="Engine" name="engine">
                        <Input type={"number"} placeholder="Engine"/>
                    </Form.Item>

                    <Form.Item label="Horse Power" name="horsePower">
                        <Input type={"number"} placeholder="Horse Power"/>
                    </Form.Item>

                    <Form.Item label="Transmission" name="transmission">
                        <Input placeholder="Transmission"/>
                    </Form.Item>

                    <Form.Item label="Fuel" name="fuel">
                        <Input type={"number"} placeholder="Fuel"/>
                    </Form.Item>

                    <Form.Item label="Fuel Grade" name="fuelGrade">
                        <Input type={"number"} placeholder="Fuel Grade"/>
                    </Form.Item>

                    <Form.Item label="Fuel Capacity" name="fuelCapacity">
                        <Input type={"number"} placeholder="Fuel Capacity"/>
                    </Form.Item>

                    <Form.Item label="Wheel Diameter" name="wheelDiameter">
                        <Input type={"number"} placeholder="Wheel Diameter"/>
                    </Form.Item>

                    <Form.Item label="Wheel Width" name="wheelWidth">
                        <Input type={"number"} placeholder="Wheel Width"/>
                    </Form.Item>

                    <Form.Item label="Tyre Number" name="tyreNumber">
                        <Input type={"number"} placeholder="Tyre Number"/>
                    </Form.Item>

                    <Form.Item label="Tyre Size" name="tyreSize">
                        <Input type={"number"} placeholder="Tyre Size"/>
                    </Form.Item>
                </>
            ),
        },
        {
            key: 'size',
            label: `Size`,
            children: (
                <>

                    <Form.Item label="Length" name="length">
                        <Input type={"number"} placeholder="Length"/>
                    </Form.Item>

                    <Form.Item label="Width" name="width">
                        <Input type={"number"} placeholder="Width"/>
                    </Form.Item>

                    <Form.Item label="Height" name="height">
                        <Input type={"number"} placeholder="Height"/>
                    </Form.Item>

                    <Form.Item label="Weight" name="weight">
                        <Input type={"number"} placeholder="Weight"/>
                    </Form.Item>

                    <Form.Item label="Passenger Capacity" name="passengerCapacity">
                        <Input type={"number"} placeholder="Passenger Capacity"/>
                    </Form.Item>
                </>
            ),
        }
    ];

    return (
        <>
            <Tabs defaultActiveKey="profile" items={items}/>
        </>
    );
}


export default VehicleTabbedForm;
