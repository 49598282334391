import React, {useContext, useEffect, useRef, useState} from "react";
import type {InputRef} from "antd";
import {Button, Form, Input, Space, Tooltip} from "antd";
import type {FormInstance} from "antd/es/form";
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string;
    record: any;
    handleSave: (record: any) => void;
    component?: (form: FormInstance<any>, onSave: () => void) => React.ReactNode;

    action?: (form: FormInstance<any>, onSave: () => void) => React.ReactNode;
}

export const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props}/>
            </EditableContext.Provider>
        </Form>
    );
};

export const EditableCell: React.FC<EditableCellProps> = ({
                                                              title,
                                                              editable,
                                                              children,
                                                              dataIndex,
                                                              record,
                                                              handleSave,
                                                              component,
                                                              action,
                                                              ...restProps
                                                          }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing && !component && inputRef.current) {
            inputRef.current!.focus();
        }
    }, [component, editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({[dataIndex]: record[dataIndex]});
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing
            ? (

                <>

                    {
                        (component) ? (
                            <>
                                {
                                    component(form, save)
                                }
                                <Form.Item name={dataIndex} style={{display: "none"}} hidden>
                                    <Input/>
                                </Form.Item>
                            </>
                        ) : (
                            <Form.Item
                                style={{margin: 0}}
                                name={dataIndex}
                                rules={[
                                    {
                                        required: true,
                                        message: `${title} is required.`
                                    }
                                ]}
                            >
                                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
                            </Form.Item>
                        )
                    }
                    <Space>
                        {action && action(form, save)}
                        <Tooltip title='Save'>
                            <Button icon={<CheckOutlined/>} onClick={save}/>
                        </Tooltip>
                        <Tooltip title={'Cancel'}>
                            <Button icon={<CloseOutlined/>} onClick={() => setEditing(!editing)}/>
                        </Tooltip>
                    </Space>
                </>
            ) : (
                <span
                    className="editable-cell-value-wrap"
                    style={{
                        cursor: "pointer",
                        paddingRight: 24,
                        minHeight: 32,
                        display: "block"
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </span>
            );
    }

    return <td {...restProps}>{childNode}</td>;
};
