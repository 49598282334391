export interface IForgetPasswordRequest {
    email: string;
}

export interface IForgetPasswordResponse {
    response: {
        message: string;
        type: string;
    }
}


export interface IResetPasswordRequest {
    token: string;
    password: string;
}


export interface IResetPasswordResponse {
    error?: {
        message: string;
        type: string;
    };
    success?: {
        message: string;
        type: string;
    }
}

// Object is instance of IResetPasswordResponse
export function isIResetPasswordResponse(object: any): object is IResetPasswordResponse {
    return 'success' in object || 'error' in object;
}
