import {Badge, Col, Form, Row, Select, Typography} from "antd";
import {COLOR_OFFLINE, COLOR_ONLINE, COLOR_PRIMARY} from "App";
import React, {useCallback, useMemo} from "react";
import {useGetPaginatedDriversListQuery} from "../../redux/services/losant/drivers";
import {IHoloObjectDropdownProps} from "./IHoloObjectDropdownProps";
import {DriverSerializer} from "../../contracts/holotrak/driver";


const DriversDropdown: React.FC<IHoloObjectDropdownProps> = ({fieldName, onBlur, onClear, onSelect}) => {
    const form = Form.useFormInstance();
    const currentValue = form.getFieldValue(fieldName);

    const [driverSearch, setDriverSearch] = React.useState<string>("");
    const {isLoading: isLoadingDriversList, drivers} = useGetPaginatedDriversListQuery({
        page: 0,
        limit: 15,
        fieldValue: '',
        searchValue: driverSearch,
    }, {
        selectFromResult: (result) => {
            return {
                isLoading: result.isLoading,
                drivers: result?.data?.items?.map((driver) => DriverSerializer.parse(driver)) ?? []
            }
        }
    });

    const driverOptions = useMemo(() => {
        const completeOptions = [
            {value: "drivers", label: <>Drivers</>}
        ]
        if (!isLoadingDriversList) {
            drivers?.forEach((driver) => {
                completeOptions.push(
                    {
                        value: driver.id,
                        label: (
                            <Row gutter={4}>
                                <Col span={2} style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    justifyContent: 'flex-end'
                                }}>
                                    <Badge color={
                                        driver.vehicle ? COLOR_OFFLINE : COLOR_ONLINE
                                    } title='Driver Assigned to a Vehicle'/>
                                </Col>
                                <Col span={22}>
                                    <Typography.Text ellipsis={{
                                        tooltip: driver.name
                                    }}>
                                        {driver.name}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        ),
                    }
                );
            })
        }

        return completeOptions;
    }, [isLoadingDriversList, drivers]);

    const ownOnSelect = useCallback((value: any, option: any) => {
        form.setFieldValue(fieldName, value);

        if (onSelect) {
            onSelect(value, option);
        }
    }, [fieldName, form, onSelect]);

    const ownOnClear = useCallback(() => {
        form.setFieldValue(fieldName, undefined);

        if (onClear) {
            onClear();
        }
    }, [fieldName, form, onClear]);

    return (
        <Select
            allowClear
            showSearch
            filterOption={false}
            onClear={ownOnClear}
            onSelect={ownOnSelect}
            onBlur={onBlur}
            value={currentValue}
            onSearch={(value) => setDriverSearch(value)}
            options={driverOptions}
        />
    )
}

export default DriversDropdown;
