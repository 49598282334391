import {GeofenceListItem} from "./GeofenceListItem";
import {Input, Spin} from "antd";
import React, {useCallback, useState} from "react";

import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList as List} from "react-window";
import {useTypedSelector} from "../../../redux/store";
import {selectGeofenceList} from "../../../redux/slices/app";
import {selectGeofenceWithDevices, useGetGeofenceListQuery} from "../../../redux/services/losant/geofences";
import {Geofence} from "../../../contracts/holotrak/geofence";
import {ILosantListQuery} from "../../../contracts/ILosantListQuery";

interface IGeofenceListProps {
    onEditClick?: (geofence: Geofence) => void;
    onBadgeClick?: (geofence: Geofence) => void;
}

export const GeofenceList: React.FC<IGeofenceListProps> = (props) => {
    const [geofenceSearch, setGeofenceSearch] = useState<ILosantListQuery>({
        searchValue: "",
        fieldValue: ""
    });

    const {isLoading: loadingGeofenceList} = useGetGeofenceListQuery(geofenceSearch);

    const geofenceList = useTypedSelector(state => selectGeofenceWithDevices(state, geofenceSearch));

    const selectedGeofenceList = useTypedSelector(selectGeofenceList);

    const handleGeofenceSearch = useCallback((e) => {
        setGeofenceSearch(value => ({
            ...value,
            searchValue: (e?.target?.value) ? e.target.value : ""
        }));
    }, []);

    return (
        <>
            <Input.Search
                allowClear
                value={geofenceSearch.searchValue}
                onChange={handleGeofenceSearch}
                onSearch={handleGeofenceSearch}/>

            <Spin spinning={loadingGeofenceList}>
                <div className='geofence-list--container'>
                    <AutoSizer>
                        {
                            ({height, width}) => (
                                <List
                                    className="geofence-list"
                                    height={height}
                                    width={width}
                                    itemCount={geofenceList ? geofenceList.length : 0}
                                    itemSize={64}
                                >
                                    {({index, style}) => {
                                        const geofence = geofenceList[index];
                                        const geofenceId = geofence ? geofence.id : index;
                                        const isChecked = geofence
                                            &&
                                            selectedGeofenceList.findIndex(({id}) => id === geofence.id) >= 0;

                                        return geofence
                                            ? (
                                                <GeofenceListItem
                                                    isChecked={isChecked}
                                                    geofence={geofence}
                                                    onEditClick={props.onEditClick}
                                                    onBadgeClick={props.onBadgeClick}
                                                    style={style}
                                                    key={geofenceId}/>
                                            )
                                            : null;
                                    }}
                                </List>
                            )
                        }
                    </AutoSizer>
                </div>
            </Spin>
        </>
    );
}
