import {losantApi} from "./index";
import {ILosantListQuery} from "contracts/ILosantListQuery";
import {NotificationListResponse} from "contracts/holotrak/notification";
import {TAG_NOTIFICATION} from "../../../contracts/reduxResourceTags";

const notificationApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        getNotificationList: builder.query<NotificationListResponse, ILosantListQuery>({
            providesTags: (result) => {
                return [
                    ...result.items.map(fence => ({
                        type: TAG_NOTIFICATION,
                        id: fence.id
                    })), TAG_NOTIFICATION
                ];
            },
            query: (queryArgs) => {
                let sortDirection = (queryArgs.sort === 'ascend') ? 'asc' : `desc`;
                let defaultQueryParams = `sortField=createdAt&sortDirection=${sortDirection}`;
                return {
                    url: `/notifications?page=${queryArgs.page}&pageSize=${queryArgs.limit}&searchValue=${queryArgs.searchValue}&fieldValue=${queryArgs.fieldValue}&${defaultQueryParams}`,
                    method: "get",
                };
            }
        }),
    })
});

export const {
    useGetNotificationListQuery
} = notificationApi;
