import {Button, Space} from "antd";
import {IconFilter} from "assets/icons/icon-filter";
import {DateTimeRenderer} from "components/datetime";
import PaginatedList, {HolotrakColumnConfig} from "components/paginated-list";
import ColumnConfigurator from "components/paginated-list/ColumnConfigurator";
import {EGroupType, getRouteForGroupType} from "contracts/EGroupType";
import {EResource} from "contracts/EResource";
import {Device, DeviceSerializer} from "contracts/holotrak/device";
import dayjs from "dayjs";
import React, {useCallback, useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useGetDeviceExpiryReportQuery} from "redux/services/express/reports";
import useTablePagination from "utils/useTablePagination";
import useToggleValue from "utils/useToggleValue";


export const DeviceReport: React.FC = () => {
    const {
        searchTerm,
        handleSearch,
        pagination,
        handleTableChange,
    } = useTablePagination({
        pageSize: 30
    });

    const navigate = useNavigate();

    const [columns, setColumns] = useState<HolotrakColumnConfig>([
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            column: true,
            sorter: (a: Device, b: Device) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
            showSorterTooltip: true,
        },
        {
            title: 'IMEI',
            dataIndex: 'mobileId',
            key: 'mobileId',
            column: true,
            render: (item, record) => record.getTagValue('mobileId')
        },
        {
            title: 'Asset Linked',
            dataIndex: 'vehicleName',
            key: 'vehicleName',
            column: true,
        },
        {
            title: 'Assigned Driver',
            dataIndex: 'driverName',
            key: 'driverName',
            column: true,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            column: true,
            render: (item, record) => record.getTagValue('type')
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            column: true,
            render: value => value || '',
        },
        {
            title: 'Last known Location',
            dataIndex: 'additionalInfo',
            key: 'additionalInfo',
            column: false,
            render: value => (value === '--' ? '' : value || ''),
        },
        {
            title: 'Last Reported At',
            dataIndex: 'compositeState.last_reported_at',
            key: 'last_reported_at',
            column: true,
            render: (value, record: Device) => (
                <DateTimeRenderer date={record.getCompositeStateItem('last_reported_at').time}/>
            ),
            renderData: (item, record: Device) => {
                return record.getCompositeStateItem('last_reported_at')?.time?.format('YYYY-MM-DD HH:mm:ss') ?? '';
            },
        },
        {
            title: 'Device Plan',
            dataIndex: 'plan',
            key: 'plan',
            column: true,
            render: (item, record) => (record && record.plan && record.plan.name) || '',
        },
        {
            title: 'Expiration Date',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            column: true,
            render: (item) => <DateTimeRenderer date={item} onlyDate/>,
            renderData: (item: dayjs.Dayjs) => item?.format('YYYY-MM-DD') ?? '',
        },
        {
            title: 'Activation Date',
            dataIndex: 'activationDate',
            key: 'activationDate',
            column: false,
            render: (item) => <DateTimeRenderer date={item} onlyDate/>,
            renderData: (item: dayjs.Dayjs) => item?.format('YYYY-MM-DD') ?? '',
        },
        {
            title: 'Next Billing',
            dataIndex: 'expiryDate',
            key: 'billingDate',
            column: false,
            render: (item) => <DateTimeRenderer date={item}/>,
            renderData: (item: dayjs.Dayjs) => item?.format('YYYY-MM-DD') ?? '',
        },
        {
            title: 'Creation Date',
            dataIndex: 'createdAt',
            key: 'creationDate',
            sorter: true,
            render: (item) => <DateTimeRenderer date={item} onlyDate/>,
            renderData: (item: dayjs.Dayjs) => item?.format('YYYY-MM-DD') ?? '',
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
            column: false,
            sorter: true,
            render: (item) => <DateTimeRenderer date={item}/>,
            renderData: (item: dayjs.Dayjs) => item?.format('YYYY-MM-DD') ?? '',
        },
    ]);

    const {isLoading, data: tableResponse} = useGetDeviceExpiryReportQuery(null, {
        selectFromResult: ({isLoading, data}) => {
            return {
                data: {
                    totalCount: data?.totalCount ?? 0,
                    items: data?.items?.map((item) => DeviceSerializer.parse(item)) || [],
                },
                isLoading
            };
        },
    });
    const records = useMemo(() => {
        return isLoading ? [] : tableResponse.items.filter((item) => {
            return (
                item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.mobileId.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
    }, [isLoading, searchTerm, tableResponse.items]);


    const [isFilterOpen, toggleFilterOpen] = useToggleValue(false);
    const filterSupport = useMemo(() => {
        return (
            <div className="filter-support">
                <Button type='default' shape='circle' icon={<IconFilter/>} onClick={toggleFilterOpen}/>
            </div>
        );
    }, [toggleFilterOpen]);

    const renderGridContent = useCallback((item: Device) => {
        return !item ? null : (
            <ul>
                <li>
                    <h4>Driver</h4>
                    <p>{item.driverName ?? '-'}</p>
                </li>
                <li>
                    <h4>Asset</h4>
                    <p>{item.vehicle.name ?? '-'}</p>
                </li>
                <li>
                    <h4>Location</h4>
                    <p>{item.additionalInfo ?? '-'}</p>
                </li>
            </ul>
        );
    }, []);

    return (
        <>
            <PaginatedList
                title="Devices"
                resource={EResource.DEVICES}
                onSearch={handleSearch}
                pagination={pagination}
                onChange={handleTableChange}
                isLoading={isLoading}
                dataSource={records}
                columns={columns}
                additionalHeader={filterSupport}
                totalCount={tableResponse?.totalCount}
                showTotal={true}
                afterContent={(
                    <div className='bottom-bar'>
                        <Space size={24}>
                            <Button
                                size='small'
                                onClick={() => {
                                    navigate(`/${getRouteForGroupType(EGroupType.DEVICE)}`);
                                }}
                            >
                                Map
                            </Button>
                        </Space>
                    </div>
                )}

                hasGridView={true}
                gridConfig={{
                    title: (item: Device) => item?.name,
                    gridContent: (item: Device) => renderGridContent(item)
                }}

            />
            <ColumnConfigurator
                isConfiguring={isFilterOpen}
                toggleConfigurationMode={toggleFilterOpen}
                columns={columns}
                setColumns={setColumns}
            />
        </>
    );
};

export default DeviceReport;
