import React, {useCallback, useMemo, useState} from 'react';
import {Geofence} from "../../../contracts/holotrak/geofence";
import {Button, Drawer, Space, Tooltip, Typography} from "antd";

import {IconFilter} from "../../../assets/icons/icon-filter";
import useToggleValue from "../../../utils/useToggleValue";
import ColumnConfigurator from "../../paginated-list/ColumnConfigurator";
import PaginatedList from "../../paginated-list";
import {DateTimeRenderer} from "../../datetime";
import {IconExport} from "../../../assets/icons/icon-export";
import {EResource} from "../../../contracts/EResource";
import {exportToXlsx} from "../../../utils/exportToXlsx";

const {Paragraph} = Typography;

interface IGeofenceDeviceDrawerProps {
    geofence: Geofence;
    toggle: (geofence: Geofence) => void;
}

const GeofenceDeviceDrawer: React.FC<IGeofenceDeviceDrawerProps> = (props) => {
    const {geofence, toggle} = props;

    const title = useMemo(() => `Geofence Devices - ${geofence?.name}`, [geofence]);

    const [isConfiguringColumns, toggleColumnConfigurator] = useToggleValue(false);

    const [columns, setColumns] = useState([
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            column: true,
            sorter: true,
        },
        {
            title: 'Last known Location',
            dataIndex: 'additionalInfo',
            key: 'additionalInfo',
            column: true,
            render: (item) => <Paragraph ellipsis={{rows: 1, tooltip: true}}>{item}</Paragraph>,
        },
        {
            title: 'IMEI',
            dataIndex: 'mobileId',
            key: 'mobileId',
            column: false,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            column: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            column: true,
            render: value => value || '',
        },
        {
            title: 'Creation Date',
            dataIndex: 'createdAt',
            key: 'creationDate',
            sorter: true,
            column: false,
            render: (item) => <DateTimeRenderer date={item}/>,
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
            column: false,
            sorter: true,
            render: (item) => <DateTimeRenderer date={item}/>,
        },
    ]);

    const exportExcel = useCallback(() => {
        return exportToXlsx(
            'holotrak',
            title,
            geofence?.locatableItems,
            columns,
        );
    }, [columns, geofence, title]);


    return geofence ? (
            <Drawer
                width={450}
                title={title}
                className="geofence-drawer"
                closable={true}
                mask={true}
                onClose={() => toggle(geofence)}
                open={!!geofence}
                extra={
                    <Space>
                        <Tooltip
                            title='Export to Excel'
                        >
                            <Button
                                title='Export to Excel'
                                type='default'
                                shape='circle'
                                icon={<IconExport/>}
                                onClick={exportExcel}
                            />
                        </Tooltip>

                        <Tooltip
                            title='Configure Columns'
                        >
                            <Button
                                title='Configure Columns'
                                type='default'
                                shape='circle'
                                icon={<IconFilter/>}
                                onClick={toggleColumnConfigurator}
                            />
                        </Tooltip>
                    </Space>
                }
            >

                <PaginatedList
                    title={'Geofence Devices'}
                    resource={EResource.DEVICES}
                    isLoading={false}
                    controlHeader={false}
                    controlPageTitle={false}
                    isCompact={true}
                    columns={columns}
                    dataSource={geofence.locatableItems}
                />

                <ColumnConfigurator
                    isConfiguring={isConfiguringColumns}
                    toggleConfigurationMode={toggleColumnConfigurator}
                    columns={columns}
                    setColumns={setColumns}
                />
            </Drawer>
        )
        : null;
}

export default GeofenceDeviceDrawer;
