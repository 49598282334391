import {createSelector} from "@reduxjs/toolkit";
import {DriverBase} from "../../../contracts/holotrak/driverBase";
import {losantApi} from "./index";
import {selectDriverFilters} from "../../slices/app";
import {ILosantListQuery} from "../../../contracts/ILosantListQuery";
import {Driver, DriverPaginatedList, DriverSerializer, IDriverSaveRequest} from "../../../contracts/holotrak/driver";
import {TAG_DRIVER, TAG_VEHICLE} from "../../../contracts/reduxResourceTags";
import {selectAllDevices} from "../express";
import {DeviceSerializer} from "../../../contracts/holotrak/device";

const driversApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        getDrivers: builder.query<DriverPaginatedList, void>({
            providesTags: (result) => {
                return [
                    ...result.items.map(({id}) => ({type: TAG_DRIVER, id})),
                    {type: TAG_DRIVER, id: "LIST"},
                ];
            },
            query: () => {
                return {
                    url: "/drivers?&sortField=name&sortDirection=asc",
                    method: "get",
                };
            },
        }),

        getPaginatedDriversList: builder.query<DriverPaginatedList, ILosantListQuery>({
            providesTags: (result) => {
                return [
                    ...result.items.map(({id}) => ({type: TAG_DRIVER, id})),
                    {type: TAG_DRIVER, id: "LIST"},
                ];
            },
            query: (queryArgs: ILosantListQuery) => {
                const paginationQuery = `${queryArgs.page}&pageSize=${queryArgs.limit}`;
                const searchQuery = (queryArgs.searchValue) ? `&filterField=name&filterValue=${queryArgs.searchValue}` : '';
                const sortQuery = (queryArgs.sort && queryArgs.sortBy) ? `&sortField=${queryArgs.sortBy}&sortDirection=${queryArgs.sort === 'ascend' ? 'asc' : 'desc'}` : '';

                return {
                    url: `/drivers?page=${paginationQuery}${searchQuery}${sortQuery}`,
                    method: "get",
                }
            }
        }),


        getDriverDetails: builder.query<Driver, string>({
            query: (driverId) => {
                return {
                    url: `/driver/${driverId}`,
                    method: "get",
                };
            },
            transformResponse: (response: any) => {
                return response?.items[0];
            },
        }),

        updateDriver: builder.mutation<Driver, IDriverSaveRequest>({
            invalidatesTags: (result, error, arg) => {
                return [{
                    type: TAG_DRIVER,
                    id: "LIST",
                }, {
                    type: TAG_VEHICLE,
                    id: "LIST",
                }, {
                    type: TAG_DRIVER,
                    id: arg.data.id,
                }];
            },
            query: (driverBody) => {
                let driverData = {
                    ...driverBody.data,
                    vehicle: driverBody.data.vehicle?.id,
                };

                if (!driverBody.data.vehicle?.id) {
                    driverData.vehicle = '';
                }

                return {
                    url: `/drivers/${driverBody.data.id}`,
                    method: "put",
                    body: {
                        data: driverData
                    },
                };
            },
        }),

        createDriver: builder.mutation<Driver, IDriverSaveRequest>({
            invalidatesTags: (result, error, arg) => {
                return [{
                    type: TAG_DRIVER,
                    id: "LIST",
                }, {
                    type: TAG_VEHICLE,
                    id: "LIST",
                }, {
                    type: TAG_DRIVER,
                    id: arg.data.id,
                }];
            },
            query: (driverBody) => {
                return {
                    url: `/driver`,
                    method: "post",
                    body: {
                        data: {
                            ...driverBody.data,
                            vehicle: driverBody.data.vehicle?.id,
                        }
                    },
                };
            },
        }),


        deleteDriver: builder.mutation<any, string>({
            invalidatesTags: (result, error, driverId) => {
                return [{
                    type: TAG_DRIVER,
                    id: "LIST",
                }, {
                    type: TAG_DRIVER,
                    id: driverId,
                }];
            },
            query: (driverId) => {
                return {
                    url: `/drivers/${driverId}`,
                    method: "delete",
                };
            }
        }),

    })
});


export const {
    useGetDriversQuery,
    useGetPaginatedDriversListQuery,
    useGetDriverDetailsQuery,
    useUpdateDriverMutation,
    useCreateDriverMutation,
    useDeleteDriverMutation
} = driversApi;

export const selectDriversResult = (appState) => driversApi.endpoints.getDrivers.select()(appState);

export const selectDriverById = (appState, queryArgs) =>
    driversApi.endpoints.getDriverDetails.select(queryArgs)(appState);
export const selectDrivers = createSelector(
    [
        selectDriversResult,
        selectAllDevices
    ],
    (drivers, devices) => {
        return drivers.data?.items.map((driverData: DriverBase) => {
            let driver = DriverSerializer.parse(driverData);

            if (driver.vehicle?.losantDeviceId) {
                driver.device = DeviceSerializer.parse(devices.find(device => {
                    return device.id === driver.vehicle?.losantDeviceId;
                }));
            }

            return driver;
        }) || [];
    }
);

export const selectFilteredDrivers = createSelector(
    [
        selectDrivers,
        selectDriverFilters,
    ],
    (drivers, filters) => {
        if (!filters?.search) {
            return drivers;
        }
        return drivers.filter((driver) => driver.name.toLowerCase().includes(filters.search.toLowerCase())) || [];
    });

export const selectDriverByName = createSelector(
    [
        selectDrivers,
        (_, filter) => filter.toLowerCase(),
    ],
    (drivers, filter) => {
        if (!filter) {
            return drivers;
        }
        return drivers.filter((driver) => driver.name.toLowerCase().includes(filter)) || [];
    });
