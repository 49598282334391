import {EDeviceStatus, ELosantDeviceClass} from "contracts/EDeviceStatus";
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {CompositeState, CompositeStateType} from "./compositeState";
import {CompositeStateItem} from "./compositeStateItem";
import {DeviceSerializer} from "./device";
import {IMappable} from "../IMappable";
import L from "leaflet";
import {VehicleBase} from "./vehicleBase";
import {DriverBase, DriverBaseSerializer} from "./driverBase";
import {DeviceBase, StoredDevice} from "./deviceBase";
import {LosantPagination} from "./losantPagination";
import {IOutdoorLocatable} from "../IOutdoorLocatable";
import dayjs from "dayjs";
import {IDeviceStatus} from "../IDeviceStatus";
import {EBatteryLevel} from "../EBatteryLevel";
import {EDeviceCommandTypes} from "../EDeviceCommandTypes";

@jsonObject()
export class Vehicle extends VehicleBase implements IOutdoorLocatable, IMappable {

    @jsonMember(DeviceBase, {
        deserializer: (value: any) => {
            let device: DeviceBase;
            try {
                if (typeof value === 'string' || typeof value === 'object') {
                    if (typeof value === 'object' || (value.startsWith('{') && value.endsWith('}'))) {
                        device = DeviceSerializer.parse(value);
                    } else {
                        device = new DeviceBase();
                        device.id = value;  
                    }
                }
            } catch (e) {
                console.error('Error Parsing Device for Vehicle', e);
            }

            return device;
        }
    })
    device?: StoredDevice;
    @jsonMember(DriverBase, {
        deserializer: (value: any) => {
            let driver: DriverBase;
            try {
                if (value && Object.keys(value).length > 0) {
                    driver = DriverBaseSerializer.parse(value);
                } else if (typeof value === 'string') {
                    if (value.startsWith('{') && value.endsWith('}')) {
                        driver = DriverBaseSerializer.parse(value);
                    } else {
                        driver = new DriverBase();
                        driver.id = value;
                    }
                }

            } catch (e) {
                console.error('Error Parsing Driver for Vehicle', e);
            }

            return driver;
        }
    })
    driver?: Partial<DriverBase>;

    get deviceId(): string {
        return this.device?.id || '';
    }

    set deviceId(value: string) {
        this.device = new DeviceBase();
        this.device.id = value;
    }

    get driverId(): string {
        return this.driver?.id || '';
    }

    set driverId(value: string) {
        this.driver = new DriverBase();
        this.driver.id = value;
    }

    get uiHeight(): number {
        return (this.additionalInfo || this.additionalInfo === '') ? 111 : 84;
    }

    get additionalInfo(): string {
        return this.device?.additionalInfo || '-';
    }

    get creationDate(): dayjs.Dayjs {
        return this.device?.creationDate;
    }

    get deviceClass(): ELosantDeviceClass {
        return this.device?.deviceClass;
    }

    get lastUpdated(): dayjs.Dayjs {
        return this.device?.lastUpdated;
    }

    get mobileId(): string {
        return this.device?.mobileId;
    }

    get status(): EDeviceStatus {
        return this.device?.status;
    }

    get statusInfo(): IDeviceStatus {
        return this.device?.statusInfo;
    }

    get batteryLevel(): EBatteryLevel {
        return this.device?.batteryLevel ?? EBatteryLevel.NOT_CONNECTED;
    }

    get batteryText(): string {
        return this.device?.batteryText ?? 'NA';
    }

    get voltageLevel(): string {
        return this.device?.compositeState?.voltage_level?.value.toString() ?? 'NA'
    }

    get lotName(): string {
        return this.device?.compositeState?.lotName?.toString() ?? ''
    }

    get compositeState(): CompositeState {
        return this.device?.compositeState;
    }

    get isMoving(): boolean {
        return this.device?.isMoving;
    }

    get isOffline(): boolean {
        return this.device?.isOffline;
    }

    get latLng(): L.LatLng {
        return this.device?.latLng;
    }

    get isMappable(): boolean {
        return true;
    }

    getCommand(commandType: EDeviceCommandTypes): string | void {
        return this.device?.getCommand(commandType);
    }

    getTagValue(name: string): string | null {
        return this.device?.getTagValue(name);
    }

    getCompositeStateItem(index: CompositeStateType): CompositeStateItem {
        return this.device?.getCompositeStateItem(index);
    }

    getCompositeValue(index: CompositeStateType, suffix: string, fallback: string, alwaysShowSuffix: boolean): string {
        return this.device?.getCompositeValue(index, suffix, fallback, alwaysShowSuffix);
    }
}

@jsonObject()
export class VehiclePaginatedList extends LosantPagination {
    @jsonArrayMember(Vehicle)
    items: Vehicle[];
}

export type IVehicleSaveRequest = {
    data: Partial<Vehicle>;
}

export const VehicleSerializer = new TypedJSON(Vehicle);
