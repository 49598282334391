import {ActivityLogListResponse, IActivityLogRequest} from "contracts/holotrak/activityLog";
import {losantApi} from "./index";
import {ILosantListQuery} from "../../../contracts/ILosantListQuery";

const activeLogApi = losantApi.injectEndpoints({
    endpoints: (builder) => ({
        getActiveLogs: builder.query<ActivityLogListResponse, ILosantListQuery>({
            query: (queryArgs) => {
                const paginationQuery = `${queryArgs.page}&pageSize=${queryArgs.limit}`;
                let {searchValue, fieldKey, fieldValue} = queryArgs;
                if (searchValue) {
                    fieldKey = 'userName';
                    fieldValue = queryArgs.searchValue;
                }

                const fieldQuery = (fieldKey && fieldValue) ? `&field=${fieldKey}&fieldValue=${fieldValue}` : '';

                const dateQuery = (queryArgs.startDateRange && queryArgs.endDateRange) ? `&startDateRange=${queryArgs.startDateRange}&endDateRange=${queryArgs.endDateRange}` : '';

                const sortQuery = `&sortField=createdAt&sortDirection=desc`;

                return {
                    url: `/activity-logs?page=${paginationQuery}${fieldQuery}${sortQuery}${dateQuery}`,
                    method: "get",
                };
            },
        }),

        createActivityLog: builder.mutation<void, IActivityLogRequest>({
            query: (activityBody) => ({
              url: `/activity-logs`,
              method: 'POST',
              body: activityBody,
            }),
          }),
       
        
    })
});

export const {
    useGetActiveLogsQuery,
    useCreateActivityLogMutation
} = activeLogApi;
