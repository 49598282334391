import {TripPoint} from "./tripPoint";
import {jsonArrayMember, jsonMember, jsonObject} from "typedjson";


@jsonObject()
export class TripDeviceTags {
    [key: string]: string[];
}

@jsonObject()
export class TripDeviceHistory {

    @jsonMember(String)
    name: string;

    @jsonArrayMember(TripPoint)
    points: TripPoint[];

    @jsonMember(TripDeviceTags,
        {
            deserializer: (value: any) => {
                let parsedData: TripDeviceTags = new TripDeviceTags();

                if (value) {
                    Object.keys(value).forEach((deviceTagKey) => {
                        parsedData[deviceTagKey] = value[deviceTagKey] as string[];
                    });
                }

                return parsedData;
            }
        })
    tags: TripDeviceTags;

}
