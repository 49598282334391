import React, {useCallback, useMemo} from "react";
import {selectIndoorDrawerOpen, setIndoorDrawer, toggleIndoorLayoutFromList} from "redux/slices/app";
import {useAppDispatch, useTypedSelector} from "redux/store";

import "./bottom-bar.scss";
import {Button, Space} from "antd";
import AccessControl from "../core/AccessControl";
import {IndoorLayout} from "../../contracts/holotrak/indoorLayout";
import {selectCurrentLayout, selectIndoorLayoutsAndAssets} from "../../redux/services/losant/indoorLayouts";
import {ILosantListQuery} from "../../contracts/ILosantListQuery";
import IndoorLayoutDropdown from "../dropdowns/IndoorLayoutDropdown";
import {Link} from "react-router-dom";
import {EPermission} from "../../contracts/EPermission";
import {EResource} from "../../contracts/EResource";

interface IIndoorBottomBarProps {
    children?: React.ReactNode;
}

export const IndoorBottomBar: React.FC<IIndoorBottomBarProps> = ({children}) => {
    const dispatch = useAppDispatch();

    const isIndoorLayoutsDrawerOpen = useTypedSelector(selectIndoorDrawerOpen);

    const toggleIndoorLayoutsDrawer = useCallback(() => {
        dispatch(setIndoorDrawer(!isIndoorLayoutsDrawerOpen));
    }, [dispatch, isIndoorLayoutsDrawerOpen]);


    const indoorLayoutSearch = useMemo<ILosantListQuery>(() => ({
        searchValue: "",
        fieldValue: ""
    }), []);
    const indoorLayoutsList: IndoorLayout[] = useTypedSelector(state => selectIndoorLayoutsAndAssets(state, indoorLayoutSearch));

    const currentIndoorLayout = useTypedSelector(selectCurrentLayout);

    const handleLayoutChange = useCallback((value) => {
        if (!value || indoorLayoutsList.length === 0) return;
        const indoorLayout = indoorLayoutsList.find(layout => layout.id === value);
        dispatch(
            toggleIndoorLayoutFromList(
                indoorLayout ? indoorLayout : indoorLayoutsList[0]
            )
        )
    }, [dispatch, indoorLayoutsList]);

    return (
        <div className='bottom-bar'>
            <Space size={24}>
                <AccessControl
                    sidebar
                    resource={EResource.INDOOR_LAYOUT}
                    permissionNeeded={EPermission.READ}
                    render={accessProps => (
                        <Space size={24}>
                            <IndoorLayoutDropdown
                                value={currentIndoorLayout?.id}
                                onSelect={handleLayoutChange}
                                onClear={handleLayoutChange}
                            />

                            <Link to={'/report/indoor-assets'}>
                                <Button size='small'>
                                    List
                                </Button>
                            </Link>

                            <Button size='small' onClick={toggleIndoorLayoutsDrawer}>
                                Manage Layouts
                            </Button>
                        </Space>
                    )}
                />
                {children}
            </Space>
        </div>
    )
}
