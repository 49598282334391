import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';

import {DeviceTripSummaries} from "../contracts/holotrak/TripSummaryTableRecord";
import {prepareDataForExport} from "./exportUtils";
import {TableProps as RcTableProps} from "rc-table/lib/Table";
import {HolotrakColumnConfig} from "../components/paginated-list";

export const exportToXlsx = (key: string, title: string, records: RcTableProps<any>['data'], columns: HolotrakColumnConfig) => {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx' //type === 'excel' ? '.xlsx' : '.csv';

    const data = prepareDataForExport(records, columns);

    const ws = XLSX.utils.json_to_sheet(data);
    const sheetName = key;
    const colWidth = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
        colWidth.push({wch: 20});
    }
    ws['!cols'] = [...colWidth];

    const wb = {
        Sheets: {[sheetName]: ws},
        SheetNames: [sheetName],
    };
    const excelBuffer = XLSX.write(wb, {
        bookType: 'xlsx',//type === 'excel' ? 'xlsx' : 'csv',
        type: 'array',
    });
    const blobDataToExport = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(blobDataToExport, `${key}-${title}-${Date.now()}${fileExtension}`);
};
export const exportTripHistory = (key: string, title: string, tripsData: DeviceTripSummaries) => {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx' //type === 'excel' ? '.xlsx' : '.csv';

    /*
        (workbook, sheetConfig, i) => {
        const data = prepareDataForExport(sheetConfig.records, []);

        const workSheet = XLSX.utils.json_to_sheet(data);
        const sheetName = `${key}-${i}`;
        const colWidth = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data.length; i++) {
          colWidth.push({wch: 20});
        }
        workSheet['!cols'] = [...colWidth];

        workbook[sheetName] = workSheet;
      }
      */


    let sheets = Object.keys(tripsData).reduce((workbooks, currentDeviceKey) => {
        const deviceSummaries = tripsData[currentDeviceKey];
        const sheetName = deviceSummaries[0]?.history?.name;


        const workSheet = XLSX.utils.json_to_sheet([
            {
                "Device Name": sheetName,
            },
            {}
        ]);

        deviceSummaries.forEach((summaryRecord, index) => {
            XLSX.utils.sheet_add_json(workSheet, [
                {
                    [`Trip ${index + 1}`]: '',
                    'Start Time': summaryRecord.startTime.toString(),
                    'End Time': summaryRecord.endTime.toString(),
                    'Status': summaryRecord.tripStatus,
                    'Average Speed': summaryRecord.avgSpeed,
                    'Max Speed': summaryRecord.maxSpeed,
                    'Distance Travelled': summaryRecord.distance,
                    'Time Taken': summaryRecord.timeSpent,
                },
                {}
            ], {
                origin: -1
            });

            XLSX.utils.sheet_add_json(workSheet, [
                {},
                {},
            ], {
                origin: -1
            });


            XLSX.utils.sheet_add_json(workSheet, summaryRecord.history.points.map((point) => ({
                'Report Time': point.time.toString(),
                'Speed': point.speed,
                'Address': point.address,
                'Latitude': point.latitude,
                'Longitude': point.longitude,
            })), {
                origin: -1
            });
        });

        workSheet['!cols'] = new Array(8).fill({wch: 20});

        workbooks[sheetName] = workSheet;

        return workbooks;
    }, {});


    const sheetNames = Object.keys(sheets);


    const wb = {
        Sheets: sheets,
        SheetNames: sheetNames,
    };
    const excelBuffer = XLSX.write(wb, {
        bookType: 'xlsx',//type === 'excel' ? 'xlsx' : 'csv',
        type: 'array',
    });
    const blobDataToExport = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(blobDataToExport, `${key}-${title}-${Date.now()}${fileExtension}`);
};
