// geofence-management list management with redux toolkit

import React, {useCallback} from "react";
import {Button, Drawer, Typography} from "antd";
import {useAppDispatch, useTypedSelector} from "../../../redux/store";
import {selectGeofenceDrawerOpen, setGeofenceDrawer} from "../../../redux/slices/app";
import {CloseOutlined} from "@ant-design/icons";

import {Geofence} from "../../../contracts/holotrak/geofence";
import GeofenceManagement from "../core";

interface IGeofenceProps {
    // At present the geofence drawer is going to be tied to Redux for Data management
}

const {Title} = Typography;

export const GeofenceDrawer: React.FC<IGeofenceProps> = () => {
    const appDispatch = useAppDispatch();

    const isDrawerOpen = useTypedSelector(selectGeofenceDrawerOpen);


    const closeDrawerInRedux = useCallback(() => {
        appDispatch(setGeofenceDrawer(false));
    }, [appDispatch]);

    return (
        <Drawer
            title={`Geofence`}
            className="geofence-drawer"
            open={isDrawerOpen}
            mask={false}
            onClose={closeDrawerInRedux}
            closable={false}
            push={{
                distance: 40,
            }}
            extra={
                <Button
                    onClick={closeDrawerInRedux}
                    ghost
                    type="link"
                    icon={<CloseOutlined/>}
                />
            }
            bodyStyle={{paddingTop: 0, paddingBottom: 0}}
        >

            <GeofenceManagement/>
        </Drawer>
    );
}


export default GeofenceDrawer;
