import React from 'react';
import {EResource} from "../contracts/EResource";
import {EPermission} from "../contracts/EPermission";
import {useTypedSelector} from "../redux/store";
import {selectCurrentUser} from "../redux/slices/auth";
import {UserRole} from "../contracts/holotrak/userRole";

export const roleHasPermissionForResource = (resourceToUse: EResource, permissionNeeded: EPermission, roles: UserRole[]) => {
    let permissions = [];
    let access = 0;

    if (roles && roles.length) {
        roles.forEach(role => {
            if (role.id) {
                const rolePermissions = {};
                Object.keys(role).forEach(module => {
                    if (
                        module === 'name' ||
                        module === 'id' ||
                        module === 'organisation' ||
                        module === 'userPosition' ||
                        module === 'createdAt' ||
                        module === 'updatedAt'
                    )
                        return;
                    try {
                        rolePermissions[module] = role[module];
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log('Error in roles', e);
                    }
                });
                permissions.push(rolePermissions);
            }
        });
    }

    const finalPermission = {};
    if (permissions && permissions.length) {
        permissions.forEach(el => {
            Object.keys(el).forEach(resource => {
                if (!finalPermission[resource]) {
                    finalPermission[resource] = el[resource];
                } else {
                    const finalBitwise = Number(
                        parseInt(finalPermission[resource], 2) | parseInt(el[resource], 2),
                    ).toString(2);
                    let str = '';
                    if (finalBitwise && finalBitwise.length && finalBitwise.length < 4) {
                        switch (finalBitwise.length) {
                            case 1:
                                str = `000${finalBitwise}`;
                                break;
                            case 2:
                                str = `00${finalBitwise}`;
                                break;
                            case 3:
                                str = `0${finalBitwise}`;
                                break;
                            case 4:
                                str = finalBitwise;
                                break;
                            default:
                                break;
                        }
                        finalPermission[resource] = str;
                    }
                }
            });
        });
    }

    // permissions = finalPermission;

    const requestedResourcePermission = finalPermission[resourceToUse];
    if (!requestedResourcePermission) {
        access = 0;
    } else {
        switch (permissionNeeded) {
            case EPermission.CREATE:
                if (
                    requestedResourcePermission &&
                    requestedResourcePermission[0] === '1'
                ) {
                    access = 1;
                }
                break;
            case EPermission.READ:
                if (
                    requestedResourcePermission &&
                    requestedResourcePermission[1] === '1'
                ) {
                    access = 1;
                }
                break;
            case EPermission.UPDATE:
                if (
                    requestedResourcePermission &&
                    requestedResourcePermission[2] === '1'
                ) {
                    access = 1;
                }
                break;
            case EPermission.DELETE:
                if (
                    requestedResourcePermission &&
                    requestedResourcePermission[3] === '1'
                ) {
                    access = 1;
                }
                break;
            default:
                access = 0;
                break;
        }
    }

    if (!(roles && roles.length) && !permissions.length) {
        access = 1;
    }

    return access === 1;
};

export const useUserPermissionsForRole = (resource: EResource, permissionNeeded: EPermission) => {
    const {roles = []} = useTypedSelector(selectCurrentUser);

    const access = React.useMemo(() => {
        return roleHasPermissionForResource(resource, permissionNeeded, roles);
    }, [permissionNeeded, resource, roles]);

    return {access: access};
};
