import React, {useEffect} from 'react';
import {Vehicle} from "../../contracts/holotrak/vehicle";
import {Button, Drawer, Form, message, Space} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {Driver, DriverSerializer, IDriverSaveRequest} from "../../contracts/holotrak/driver";
import DriverTabbedForm from "./DriverTabbedForm";
import {useCreateDriverMutation, useUpdateDriverMutation} from "../../redux/services/losant/drivers";
import VehiclesDropdown from "../dropdowns/VehiclesDropdown";

interface IDriverFormProps {
    isOpen: boolean;
    onClose: () => void;
    driver: Driver;
}

const formFieldsToUse = [
    'id',
    'name',
    'vehicleId',
    'department',
    'tags',
    'issuingAuthority',
    'driverLicenceNumber',
    'licenceClass',
    'expirationDate',
    'phone',
    'email',
    'location'
];

const DriverForm: React.FC<IDriverFormProps> = ({driver, onClose, isOpen}) => {
    const [driverForm] = Form.useForm<Partial<Vehicle>>();


    const [updateDriver, {isLoading: isUpdatingDriver}] = useUpdateDriverMutation();
    const [createDriver, {isLoading: isCreatingDriver}] = useCreateDriverMutation();


    const handleDriverFormSubmit = () => {
        driverForm.validateFields().then(async values => {
            let driverData = DriverSerializer.parse(driverForm.getFieldsValue(formFieldsToUse));

            driverData.vehicleId = driverForm.getFieldValue('vehicleId');

            const formValuesToSend: IDriverSaveRequest = {
                data: driverData
            };

            if (formValuesToSend.data.id) {
                updateDriver(formValuesToSend).then(() => {
                    message.success("Driver updated successfully.");
                });
            } else {
                createDriver(formValuesToSend).then(() => {
                    onClose();
                });
            }
        });
    }


    useEffect(() => {
        driverForm.resetFields();
        driverForm.setFieldsValue(driver);
        driverForm.setFieldValue('vehicleId', driver?.vehicleId);

    }, [driver, driverForm]);

    return (
        <Drawer
            title={`Driver`}
            closable={false}
            onClose={onClose}
            size={'large'}
            open={isOpen}
            extra={
                <Space>
                    <Button
                        htmlType="submit"
                        className="grey-btn"
                        onClick={handleDriverFormSubmit}
                        loading={isUpdatingDriver || isCreatingDriver}
                    >
                        {driver?.id ? 'Update' : 'Create'}
                    </Button>
                    <Button
                        onClick={onClose}
                        type="link"
                        icon={<CloseOutlined/>}
                    />
                </Space>
            }
        >
            <Form
                name='driver-form'
                form={driverForm}
                initialValues={{
                    ...driver
                }}
                onFinish={handleDriverFormSubmit}
            >
                <Form.Item
                    label={"Select Vehicle"}
                    shouldUpdate
                >
                    {
                        (form) => (
                            <VehiclesDropdown fieldName={['vehicleId']}/>
                        )
                    }
                </Form.Item>

                <Form.Item
                    dependencies={formFieldsToUse}
                >
                    {
                        (form) => <DriverTabbedForm/>
                    }
                </Form.Item>


            </Form>
        </Drawer>
    );
}


export default DriverForm;
