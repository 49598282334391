import aws from 'aws-sdk';

const bucketName = 'holotrak-rmt';
const accessKeyId = 'AKIAS37HOW63VLEMUGND';
const secretAccessKey = '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';
const region = 'us-east-1';
const s3 = new aws.S3({
    region,
    accessKeyId,
    secretAccessKey,
});


export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export const uploadAwsUsingPreSignedUrl = async file => {
    const imageName = file.name;
    const params = {
        Bucket: bucketName,
        Key: `${Math.floor(Date.now() / 1000)}${imageName}`,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read',
    };

    const params2 = {
        Bucket: bucketName,
        Key: `${imageName}`,
    };
    // eslint-disable-next-line no-return-await
    // const putUrl = await s3.getSignedUrlPromise('putObject', params);

    const S3Upload = await s3.upload(params).promise();
    if (S3Upload && Object.keys(S3Upload).length) {
        return S3Upload.Location;
    }
    return null;
};

export const DecimalHexTwosComplement = (decimal) => {
    const size = 8;
    let hexadecimal = Math.abs(decimal).toString(16);
    while (hexadecimal.length % size !== 0) {
        hexadecimal = `${0}${hexadecimal}`;
    }

    let output = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < hexadecimal.length; i++) {
        output += (0x0f - parseInt(hexadecimal[i], 16)).toString(16);
    }

    output = (0x01 + parseInt(output, 16)).toString(16);
    return output;
}
