import {jsonMember, jsonObject, TypedJSON} from "typedjson";
import dayjs from "dayjs";


/**
 {
 "deviceId": "63fcb962b6eb2f75fb42d180",
 "planId": "62457b3783a187df4e03d737",
 "actualLocatePoints": "89",
 "isActive": true,
 "expiringAt": 1709042950018,
 "isDeleted": null,
 "updatedAt": "2023-04-06T12:53:14.579Z",
 "createdAt": "2023-02-27T14:09:10.031Z",
 "id": "63fcb98613a3f8453ea3ba2d",
 "name": "1-yr Activation and 100 locates ($49.95)",
 "includedLocatePoints": "100"
 }
 */

@jsonObject()
export class Plan {
    @jsonMember(String)
    id: string;

    @jsonMember(String)
    name: string;

    @jsonMember(String)
    deviceId: string;

    @jsonMember(String)
    planId: string;

    @jsonMember(Number, {
        deserializer: (value: any) => {
            return parseInt(value);
        }
    })
    includedLocatePoints: number;


    @jsonMember(Number, {
        deserializer: (value: any) => {
            return parseInt(value);
        }
    })
    actualLocatePoints: number;

    @jsonMember(Boolean, {deserializer: (value: any) => !!value})
    isActive: boolean;

    @jsonMember(Boolean, {deserializer: (value: any) => !!value})
    isDeleted: boolean;

    @jsonMember(Number)
    expiringAt: number;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;
}


export const planSerializer = new TypedJSON(Plan);
