import React, {useCallback, useState} from 'react';
import AccessControl from "../../core/AccessControl";
import {IconPlus} from "../../../assets/icons/icon-plus";
import {GeofenceList} from "../drawer/GeofenceList";
import {GeofenceFormDrawer} from "../drawer/GeofenceFormDrawer";
import GeofenceDeviceDrawer from "../drawer/GeofenceDeviceDrawer";
import {Typography} from "antd";
import {Geofence} from "../../../contracts/holotrak/geofence";
import {selectGeofenceDrawingMode, setGeofenceDrawingMode} from "../../../redux/slices/app";
import {useAppDispatch, useTypedSelector} from "../../../redux/store";

import './geofence-manager.scss';
import {EPermission} from "../../../contracts/EPermission";
import {EResource} from "../../../contracts/EResource";

const {Title} = Typography;

const GeofenceManagement: React.FC = () => {
    const appDispatch = useAppDispatch();
    const isDrawControlEnabled = useTypedSelector(selectGeofenceDrawingMode);


    const [devicesShownFor, setDevicesShownFor] = useState<Geofence>(null);

    const toggleDevicesShownFor = useCallback((geofence: Geofence) => {
        setDevicesShownFor((current) => {
            if (current && current.id === geofence.id) {
                return null;
            }

            return geofence;
        });
    }, []);


    const toggleDrawControl = useCallback(() => {
        appDispatch(setGeofenceDrawingMode(!isDrawControlEnabled));
    }, [appDispatch, isDrawControlEnabled]);

    React.useEffect(() => {
        appDispatch(setGeofenceDrawingMode(false));
    }, [appDispatch]);

    return (
        <>
            <AccessControl
                resource={EResource.GEO_FENCE}
                permissionNeeded={EPermission.CREATE}
                render={() => (
                    <Title className='create-geofence' onClick={toggleDrawControl} level={5}>
                        <IconPlus style={{marginRight: 8}}/>
                        Create Geofence
                    </Title>
                )}/>

            <GeofenceList onEditClick={toggleDrawControl} onBadgeClick={toggleDevicesShownFor}/>
            <GeofenceFormDrawer open={isDrawControlEnabled} toggle={toggleDrawControl}/>
            <GeofenceDeviceDrawer geofence={devicesShownFor} toggle={toggleDevicesShownFor}/>
        </>
    );
}

export default GeofenceManagement;
