export enum EGroupType {
    DEVICE = 'device',
    DRIVER = 'driver',
    VEHICLE = 'vehicle',
    USER = 'user',
    RECIPIENT = 'recipient',
    INDOOR_ASSET = 'indoor_asset',
    INDOOR_POSITION = 'indoor_position',
    REPORT = 'report',
}

export function getRouteForGroupType(groupType: EGroupType): string {
    switch (groupType) {
        case EGroupType.DEVICE:
            return 'devices';
        case EGroupType.DRIVER:
            return 'drivers';
        case EGroupType.VEHICLE:
            return 'vehicles';
        case EGroupType.REPORT:
            return 'reports';
        case EGroupType.INDOOR_ASSET:
            return 'indoor-assets';
        case EGroupType.INDOOR_POSITION:
            return 'indoor-positions';
    }

    return '/';
}

export function getGroupTypeName(groupType: EGroupType): string {
    switch (groupType) {
        case EGroupType.USER:
            return 'Users';
        case EGroupType.RECIPIENT:
            return 'Recipients';
        case EGroupType.DEVICE:
            return 'Devices';
        case EGroupType.DRIVER:
            return 'Drivers';
        case EGroupType.VEHICLE:
            return 'Vehicles';
        case EGroupType.REPORT:
            return 'Reports';
        case EGroupType.INDOOR_ASSET:
            return 'Indoor Assets';
        case EGroupType.INDOOR_POSITION:
            return 'Indoor Positions';
    }

    return '';
}

export function getGroupTypeForName(groupTypeName: string): EGroupType {
    switch (groupTypeName.toLowerCase()) {
        case 'device':
            return EGroupType.DEVICE;
        case 'driver':
            return EGroupType.DRIVER;
        case 'vehicle':
            return EGroupType.VEHICLE;
        case 'report':
            return EGroupType.REPORT;
        case 'indoor_asset':
            return EGroupType.INDOOR_ASSET;
        case 'indoor_position':
            return EGroupType.INDOOR_POSITION;
        case 'user':
            return EGroupType.USER;
        case 'recipient':
            return EGroupType.RECIPIENT;
    }

    return null;
}

export function availableGroupTypes() {
    return [
        EGroupType.DEVICE,
        EGroupType.DRIVER,
        EGroupType.VEHICLE,
    ];
}


export function hasImplementedGrouping(groupType: EGroupType): boolean {
    return availableGroupTypes().includes(groupType);
}
