import {TripDeviceHistory} from "./tripDeviceHistory";
import {jsonMember, jsonObject, TypedJSON} from "typedjson";

export class DeviceHistoryMap {
    [key: string]: TripDeviceHistory;
}

@jsonObject()
export class TripHistory {

    @jsonMember(String)
    start: string;

    @jsonMember(String)
    end: string;

    @jsonMember(DeviceHistoryMap, {
        deserializer: (json: DeviceHistoryMap) => {
            const deviceHistories: DeviceHistoryMap = {};
            Object.keys(json).forEach(key => {
                deviceHistories[key] = TypedJSON.parse(json[key], TripDeviceHistory);
            });
            return deviceHistories;
        }
    })
    devices: DeviceHistoryMap;


    // Since the devices object contains keys that are the device ids, we need to use a custom deserializer
    // which basically just loops through the keys and parses the values as TripDeviceHistory objects
    // and keeps the last one.

    @jsonMember(DeviceHistoryMap, {
        name: 'devices',
        deserializer: (json: DeviceHistoryMap) => {
            let deviceHistories: TripDeviceHistory;
            Object.keys(json).forEach(key => {
                deviceHistories = TypedJSON.parse(json[key], TripDeviceHistory);
            });
            return deviceHistories;
        }
    })
    data: TripDeviceHistory;
}

export const tripHistorySerializer = new TypedJSON(TripHistory);
