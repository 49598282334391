import React, {useContext, useMemo} from 'react';
import dayjs from "dayjs";
import {Spin, Typography} from "antd";
import {ExperienceGroup} from "../../contracts/holotrak/experienceGroup";
import {OrganisationContext} from "../../utils/organisationContext";

const {Text} = Typography;

interface IDateTimeRendererProps {
    date: dayjs.Dayjs | number | string;
    format?: string;
    onlyDate?: boolean;
    onlyTime?: boolean;
    fallback?: string;
    className?: string;
}

interface IOrganizationalDateTimeRendererProps extends IDateTimeRendererProps {
    organisation: ExperienceGroup;
}

const formatDate = `MM/DD/YYYY`;
const formatTime = `hh:mm:ss A`;

export const DateTimeRenderer: React.FC<IDateTimeRendererProps> = (props) => {
    const organisation = useContext(OrganisationContext);


    return (
        <span style={{display: 'inline-block'}}>
      <Spin spinning={!organisation}>
        {
            organisation && (
                <OrganizationalDateTimeRenderer organisation={organisation} {...props}/>
            )
        }
      </Spin>
    </span>
    );

};

export const OrganizationalDateTimeRenderer: React.FC<IOrganizationalDateTimeRendererProps> = ({
                                                                                                   organisation,
                                                                                                   date,
                                                                                                   onlyDate,
                                                                                                   onlyTime,
                                                                                                   format,
                                                                                                   className,
                                                                                                   fallback = '-'
                                                                                               }) => {
    const dateValue = dayjs(date).tz(organisation.timezone);

    const dateTimeFormat = useMemo(() => {
        if (format) return format;

        let formatTemplate = `${formatDate}, ${formatTime}`;
        if (onlyDate !== null && onlyDate) {
            formatTemplate = formatDate;
        } else if (onlyTime !== null && onlyTime) {
            formatTemplate = formatTime;
        }
        return formatTemplate;
    }, [format, onlyDate, onlyTime]);

    return (
        <Text className={className}>
            {
                (date && dateValue.isValid())
                    ? dateValue.format(dateTimeFormat)
                    : fallback
            }
        </Text>
    );
}
