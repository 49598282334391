import {jsonArrayMember, jsonObject, TypedJSON} from "typedjson";
import {LosantCoreDevice} from "./losantCoreDevice";
import {IIndoorLocatableItem} from "../IOutdoorLocatable";
import {EDeviceStatus, ELosantDeviceClass, getDeviceStatusFromString} from "../EDeviceStatus";
import {CompositeStateItem} from "./compositeStateItem";
import {CompositeStateType} from "./compositeState";
import {IndoorPosition} from "../IndoorPosition";
import {LosantPagination} from "./losantPagination";


export enum IndoorAssetMaker {
    BLUEIOT = 'blueiot',
    QUUPPA = 'quuppa',
    UNKNOWN = 'unknown'
}

@jsonObject()
export class IndoorAsset extends LosantCoreDevice implements IIndoorLocatableItem {

    get assetMaker(): IndoorAssetMaker {
        const type = this.getTagValue('device_type');
        if (type.startsWith('blueiot')) {
            return IndoorAssetMaker.BLUEIOT;
        } else if (type.startsWith('quuppa')) {
            return IndoorAssetMaker.QUUPPA;
        } else {
            return IndoorAssetMaker.UNKNOWN;
        }
    }

    get status(): EDeviceStatus {
        switch (this.assetMaker) {
            case IndoorAssetMaker.BLUEIOT:
                return getDeviceStatusFromString(this.getCompositeValue('status'));

            case IndoorAssetMaker.QUUPPA:
            default:
                return this.compositeState?.computeIndoorStatus() ?? EDeviceStatus.NEVER_CONNECTED;
        }
    }

    get uiHeight(): number {
        return 111;
    }

    get additionalInfo(): string {
        return `${this.positionX}, ${this.positionY}, ${this.positionZ}`;
    }

    /**
     * Should not be using this directly, since layoutId is intended for usage with Fixed Anchors
     * RegionId getter is better replacement for this
     * - as it takes into account the device class and id of layout/region it was last reported from.
     */
    get layoutId(): string {
        return this.getTagValue('layoutId');
    }

    get regionId(): string {
        if (this.deviceClass === ELosantDeviceClass.GATEWAY) {
            return this.layoutId;
        } else {
            return this.getCompositeValue('region_id', '', this.layoutId, false);
        }
    }

    get positionX(): number {
        return this.getPosition('position_x');
    }

    get positionY(): number {
        return this.getPosition('position_y');
    }

    get positionZ(): number {
        return 5;// this.positionValue(this.getCompositeStateItem('position_z'));
    }

    get position(): IndoorPosition {
        return new IndoorPosition(this.positionX, this.positionY, this.positionZ);
    }

    positionOnScreen(height: number): IndoorPosition {
        // Flip the Y-axis by subtracting realWorldY from the screenHeight
        return new IndoorPosition(this.positionX, height - this.positionY, this.positionZ);
    }

    private getPosition(index: CompositeStateType) {
        return this.positionValue(this.getCompositeStateItem(index));
        // const number = this.positionValue(this.getCompositeStateItem(index));
        // return number > 0 ? number : 1; // Since SVG does not support negative values or Zero
    }

    private positionValue(position: CompositeStateItem) {
        const finiteValue = position.getFiniteValue() as string;
        try {
            return parseFloat(finiteValue);
        } catch (e) {
            return 0;
        }
    }
}

@jsonObject()
export class IndoorAssetReportResponse extends LosantPagination {
    @jsonArrayMember(IndoorAsset)
    items: IndoorAsset[];
}

export const IndoorAssetSerializer = new TypedJSON(IndoorAsset);
