import {Button, Col, Drawer, FloatButton, Row, Spin} from "antd";
import {BottomBar} from "components/bottom-bar/BottomBar";


import React, {useCallback} from "react";
import {Helmet} from "react-helmet";
import {Link, Route, Routes} from "react-router-dom";
import {useAppDispatch, useTypedSelector} from "redux/store";


import {selectSearchedVehicle, useGetVehiclesQuery} from "redux/services/losant/vehicles";

import {selectLeftDrawerOpen, toggleLeftDrawer} from "../../redux/slices/app";
import {IconArrowRightCircle} from "../../assets/icons/icon-arrow-right-circle";

const VehicleList = React.lazy(() => import("./list"));
const VehicleDetails = React.lazy(() => import("components/vehicle-device-details"));
const MapPane = React.lazy(() => import("components/map-pane"));
const GeofenceDrawer = React.lazy(() => import("../../components/geofence-management/drawer/GeofenceDrawer"));


export const Vehicles: React.FC = () => {
    // Using a query hook automatically fetches data and returns query values
    const {isLoading: isLoadingVehicles, error: errorInLoadingVehicles} = useGetVehiclesQuery();
    const vehicles = useTypedSelector((state) => selectSearchedVehicle(state));

    const dispatch = useAppDispatch();
    const isLeftDrawerOpen = useTypedSelector(selectLeftDrawerOpen);
    const toggleDrawer = useCallback(() => {
        dispatch(toggleLeftDrawer());
    }, [dispatch]);

    const [bottomBarHeight, setBottomBarHeight] = React.useState(0);
    const bottomBarRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (bottomBarRef.current) {
            setBottomBarHeight(bottomBarRef.current.clientHeight);
        }
    }, [bottomBarRef]);
    return (
        <>
            <Helmet>
                <title>Vehicles</title>
                <meta name="description" content="Holotrak Managed Vehicles"/>
            </Helmet>
            <Row>
                <Col span={24}>

                    <FloatButton
                        className="left-drawer-toggle"
                        type='default'
                        shape="square"
                        onClick={toggleDrawer}
                        icon={<IconArrowRightCircle/>}
                    />

                    <Drawer
                        placement="left"
                        mask={false}
                        open={isLeftDrawerOpen}
                        closable={false}
                        getContainer={false}
                        motion={null}
                        bodyStyle={{padding: 0}}
                    >
                        <React.Suspense fallback={<Spin spinning={true}/>}>
                            <Routes>
                                <Route
                                    path="/"
                                    index
                                    element={
                                        <VehicleList
                                            error={errorInLoadingVehicles}
                                            isLoading={isLoadingVehicles}
                                            items={vehicles}
                                        />
                                    }
                                />
                                <Route path="/:id" element={<VehicleDetails/>}/>
                            </Routes>
                        </React.Suspense>
                    </Drawer>
                    <React.Suspense fallback={<Spin spinning={true}/>}>
                        <MapPane
                            stillItems={vehicles}
                            movingItems={[]}
                            consumedHeight={bottomBarHeight}
                        />
                        <GeofenceDrawer/>
                    </React.Suspense>
                    <Row ref={bottomBarRef}>
                        <Col span={24}>
                            <BottomBar>
                                <Link to={'/report/vehicles'}>
                                    <Button size='small'>
                                        List
                                    </Button>
                                </Link>
                            </BottomBar>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
