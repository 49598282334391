import {TableProps as RcTableProps} from "rc-table/lib/Table";
import {HolotrakColumnConfig} from "../components/paginated-list";

export const prepareDataForExport = (data: RcTableProps<any>['data'], columns: HolotrakColumnConfig): IExportableRow[] => {
    const dataToExport: IExportableRow[] = [];
    data.forEach((item) => {
        const colData = columns.reduce((acc, currentCol, currentIndex) => {
            const {title, dataIndex, render, renderData, exportable = true} = currentCol;
            const renderFunction = (typeof renderData === 'function') ? renderData : render;
            if (exportable) {
                acc[title.toString()] = renderFunction ? renderFunction(item[dataIndex], item, item.id) : item[dataIndex];
            }

            return acc;
        }, {});

        dataToExport.push({
            ...colData
        })
    });
    return dataToExport;
}

export const toCsv = (data: IExportableRow[]): Array<Array<string>> => {
    return data.map((row) => {
        return Object.keys(row).map((key) => {
            return row[key];
        })
    });
}


interface IExportableRow {
    [key: string]: string;
}
