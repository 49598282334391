import {createSlice} from "@reduxjs/toolkit";
import {HoloTrakMapTypes} from "contracts/EHoloTrakMapTypes";
import {IApp} from "contracts/IApp";
import {RootState} from "redux/store";
import {IRenderableTrip} from "../../../contracts/IRenderableTrip";
import {geofenceSerializer} from "../../../contracts/holotrak/geofence";
import {EGroupType} from "../../../contracts/EGroupType";
import {Group} from "../../../contracts/holotrak/group";
import {IndoorPosition} from "../../../contracts/IndoorPosition";

const initialState: IApp = {
    userIpInfo: {
        latitude: "",
        longitude: "",
        IPv4: ""
    },
    realTimeDevices: [],
    batchSelections: {
        [EGroupType.DEVICE]: [],
        [EGroupType.DRIVER]: [],
        [EGroupType.VEHICLE]: [],
        [EGroupType.REPORT]: [],
        [EGroupType.INDOOR_ASSET]: [],
        [EGroupType.INDOOR_POSITION]: [],
        [EGroupType.USER]: [],
        [EGroupType.RECIPIENT]: [],
    },
    deviceFilters: {
        search: "",
        filterType: null,
        filter: null,
    },
    vehicleFilters: {
        search: "",
    },
    driverFilters: {
        search: "",
    },
    indoorAssetFilters: {
        search: "",
    },
    geofence: {
        isDrawerOpen: false,
        geofenceList: [],
        bounds: {},
        isDrawing: false,
        draw: null,
        kmzKey: null,
    },
    indoor: {
        isDrawerOpen: false,
        isFormOpen: false,
        layoutsList: [],
        currentLayout: null,
    },
    isLeftDrawerOpen: true,
    isShowingGroups: false,
    mapConfig: {
        type: HoloTrakMapTypes.dark,
        zoom: 15,
        center: [38.6951433, -77.3409215],
        bounds: null,
        followedDevice: null,
    },
    indoorViewConfig: {
        scale: 1,
        center: new IndoorPosition(0, 0, 0),
    },
    trips: []
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {

        setUserIpInfo(state, action) {
            state.userIpInfo = action.payload;
        },

        setRealTimeDevices(state, action) {
            state.realTimeDevices = action.payload;
        },
        toggleLeftDrawer(state) {
            state.isLeftDrawerOpen = !state.isLeftDrawerOpen;
        },
        toggleDeviceGroups(state) {
            state.isShowingGroups = !state.isShowingGroups;
        },
        setDeviceFilterType(state, action) {
            state.deviceFilters.filterType = action.payload;
            state.deviceFilters.filter = null;
        },
        setDeviceFilter(state, action) {
            state.deviceFilters.filter = action.payload;
        },
        setDeviceFilterSearch(state, action) {
            state.deviceFilters.search = action.payload;
        },
        setVehicleFilterSearch(state, action) {
            state.vehicleFilters.search = action.payload;
        },
        setDriverFilterSearch(state, action) {
            state.driverFilters.search = action.payload;
        },
        setIndoorAssetFilterSearch(state, action) {
            state.indoorAssetFilters.search = action.payload;
        },
        setMapType(state, action) {
            if (action.payload === HoloTrakMapTypes.satellite && state.mapConfig.type !== HoloTrakMapTypes.satellite) {
                // Save Current Map Type to be able to switch back to Dark/Light
                state.prevMapType = state.mapConfig.type;
            }

            state.mapConfig.type = action.payload;
        },
        setMapZoom(state, action) {
            state.mapConfig.zoom = action.payload;
        },
        setMapBounds(state, action) {
            state.mapConfig.bounds = action.payload;
        },
        setMapCenter(state, action) {
            state.mapConfig.center = action.payload;
        },
        setMapLocation(state, action) {
            state.mapConfig.center = action.payload.center;
            state.mapConfig.zoom = action.payload.zoom || state.mapConfig.zoom;
        },
        setFollowedDevice(state, action) {
            state.mapConfig.followedDevice = action.payload;
        },
        setGeofenceDrawer(state, action) {
            state.geofence.isDrawerOpen = !!action.payload;
        },
        setGeoFenceBounds(state, action) {
            const {id, bounds} = action.payload;

            state.geofence.bounds[id] = bounds;
        },

        setGeofenceList(state, action) {
            state.geofence.geofenceList = action.payload;
        },
        removeGeofenceFromList(state, action) {
            const {id: geofenceId} = action.payload;
            const geofenceIndex = state.geofence.geofenceList.findIndex(({id}) => id === geofenceId);

            if (geofenceIndex !== -1) {
                state.geofence.geofenceList.splice(geofenceIndex, 1);
            }
        },
        toggleGeofenceFromList(state, action) {
            const {id: geofenceId} = action.payload;
            const geofenceIndex = state.geofence.geofenceList.findIndex(({id}) => id === geofenceId);

            if (geofenceIndex === -1) {
                state.geofence.geofenceList.push(action.payload);
            } else {
                state.geofence.geofenceList.splice(geofenceIndex, 1);
            }
        },
        setIndoorDrawer(state, action) {
            state.indoor.isDrawerOpen = !!action.payload;
        },
        setIndoorFormDrawerOpen(state, action) {
            state.indoor.isFormOpen = !!action.payload;
        },
        setIndoorLayoutToBeEdited(state, action) {
            state.indoor.currentLayout = action.payload;
        },
        toggleIndoorLayoutFromList(state, action) {
            const {id: layoutId} = action.payload;
            const layoutIndex = state.indoor.layoutsList.findIndex(({id}) => id === layoutId);

            if (layoutIndex === -1) {
                state.indoor.layoutsList = [action.payload]; // Only allow one layout to be selected at a time
            } else {
                state.indoor.layoutsList.splice(layoutIndex, 1);
            }
        },
        relocateToIndoorLayoutAt(state, action) {
            const {indoorLayout, position} = action.payload;

            const {id: layoutId} = indoorLayout;
            const layoutIndex = state.indoor.layoutsList.findIndex(({id}) => id === layoutId);
            if (indoorLayout.id && layoutIndex === -1) {
                state.indoor.layoutsList = [indoorLayout]; // Only allow one layout to be selected at a time
            }

            state.indoorViewConfig.center = position;
        },
        removeIndoorLayoutFromList(state, action) {
            const {id: layoutId} = action.payload;
            const layoutIndex = state.indoor.layoutsList.findIndex(({id}) => id === layoutId);

            if (layoutIndex !== -1) {
                state.indoor.layoutsList.splice(layoutIndex, 1);
            }
        },

        setIndoorScale(state, action) {
            state.indoorViewConfig.scale = action.payload;
        },

        setIndoorCenter(state, action) {
            state.indoorViewConfig.center = action.payload;
            state.indoorViewConfig.scale = 1;
        },

        toggleTripFromList(state, action) {
            const {id: tripId} = action.payload as IRenderableTrip;
            const tripIndex = state.trips.findIndex(({id}) => id === tripId);

            if (tripIndex === -1) {
                state.trips.push(action.payload);
            } else {
                state.trips.splice(tripIndex, 1);
            }
        },
        toggleGroupItemSelectionForType(state, action) {
            const {itemId, groupType} = action.payload as { itemId: string, groupType: EGroupType };
            const batchSelections = state.batchSelections[groupType];
            const deviceIndex = batchSelections.findIndex((id) => id === itemId);
            if (deviceIndex === -1) {
                batchSelections.push(itemId);
            } else {
                batchSelections.splice(deviceIndex, 1);
            }
        },
        toggleGroupSelectionSelection(state, action) {
            const {group, groupType} = action.payload as { group: Group, groupType: EGroupType };
            const batchSelections = state.batchSelections[groupType];

            if (group) {
                const groupItems = group.renderItems.map((item) => item.id);
                // Any of the Items in the Group are not selected
                if (groupItems.some((itemId) => !batchSelections.includes(itemId))) {
                    batchSelections.push(...groupItems.filter((itemId) => !batchSelections.includes(itemId)));
                } else {
                    // Remove all Group Items from Selection
                    groupItems.forEach((itemId) => {
                        const deviceIndex = batchSelections.findIndex((id) => id === itemId);
                        batchSelections.splice(deviceIndex, 1);
                    });
                }
            }
        },
        setGeofenceKmzKey(state, action) {
            state.geofence.kmzKey = action.payload;
        },
        setGeofenceDrawingMode(state, action) {
            state.geofence.isDrawing = !!action.payload;
        },
        setGeofenceDraw(state, action) {
            const geofenceDrawId = state?.geofence?.draw?.id;

            if (action.payload !== null) {
                state.geofence.draw = geofenceSerializer.parse({
                    ...state.geofence.draw,
                    ...action.payload
                });
            } else {
                state.geofence.draw = null;
            }

            if (geofenceDrawId) {
                const geofenceIndex = state.geofence.geofenceList.findIndex(({id}) => id === geofenceDrawId);
                if (geofenceIndex !== -1) {
                    state.geofence.geofenceList.splice(geofenceIndex, 1);
                }
            }
        }
    },
});

export const {
    setUserIpInfo,
    setRealTimeDevices,
    toggleLeftDrawer,
    toggleDeviceGroups,
    toggleGroupItemSelectionForType,
    toggleGroupSelectionSelection,
    setDeviceFilterType,
    setDeviceFilter,
    setDeviceFilterSearch,
    setVehicleFilterSearch,
    setDriverFilterSearch,
    setIndoorAssetFilterSearch,
    setMapType,
    setMapZoom,
    setIndoorScale,
    setIndoorCenter,
    setMapBounds,
    setMapCenter,
    setMapLocation,
    setFollowedDevice,
    setIndoorDrawer,
    setIndoorFormDrawerOpen,
    setIndoorLayoutToBeEdited,
    relocateToIndoorLayoutAt,
    toggleIndoorLayoutFromList,
    setGeofenceDrawer,
    setGeoFenceBounds,
    removeGeofenceFromList,
    toggleGeofenceFromList,
    toggleTripFromList,
    setGeofenceDrawingMode,
    setGeofenceKmzKey,
    setGeofenceDraw, // TODO: Change draw to Drawing
} = appSlice.actions;

export const selectUserIpInfo = (state: RootState) => state.app.userIpInfo;

export const selectSearch = (state: RootState) => state.app.deviceFilters.search;
export const selectLeftDrawerOpen = (state: RootState) => state.app.isLeftDrawerOpen;
export const selectIsShowingGroups = (state: RootState) => state.app.isShowingGroups;
export const selectDeviceFilters = (state: RootState) => state.app.deviceFilters;
export const selectDeviceFilterType = (state: RootState) => state.app.deviceFilters.filterType;
export const selectDeviceFilterValue = (state: RootState) => state.app.deviceFilters.filter;
export const selectVehicleFilters = (state: RootState) => state.app.vehicleFilters;
export const selectDriverFilters = (state: RootState) => state.app.driverFilters;
export const selectIndoorAssetFilters = (state: RootState) => state.app.indoorAssetFilters;
export const selectMapConfig = (state: RootState) => state.app.mapConfig;

export const selectIndoorViewConfig = (state: RootState) => state.app.indoorViewConfig;
export const selectPrevMapType = (state: RootState) => state.app.prevMapType;
export const selectGeofenceDrawerOpen = (state: RootState) => state.app.geofence.isDrawerOpen;
export const selectIndoorDrawerOpen = (state: RootState) => state.app.indoor.isDrawerOpen;
export const selectIndoorLayoutToBeEdited = (state: RootState) => state.app.indoor.currentLayout;
export const selectIndoorFormOpen = (state: RootState) => state.app.indoor.isFormOpen;
export const selectIndoorLayoutsList = (state: RootState) => state.app.indoor.layoutsList;
export const selectGeofenceKmzKey = (state: RootState) => state.app.geofence.kmzKey;
export const selectGeofenceList = (state: RootState) => state.app.geofence.geofenceList;
export const selectTrips = (state: RootState) => state.app.trips;
export const selectSelectionsForBatchType = (state: RootState, groupType: EGroupType) => state.app.batchSelections[groupType];
export const selectGeofenceDrawingMode = (state: RootState) => state.app.geofence.isDrawing;
export const selectGeofenceDraw = (state: RootState) => state.app.geofence.draw;
export const selectGeoFenceBounds = (state: RootState) => state.app.geofence.bounds;

export default appSlice.reducer;
