import {Badge, Col, Form, Row, Select, Typography} from "antd";
import {COLOR_OFFLINE, COLOR_ONLINE} from "App";
import React, {useCallback, useMemo} from "react";
import {useGetPaginatedVehiclesListQuery} from "../../redux/services/losant/vehicles";
import {IHoloObjectDropdownProps} from "./IHoloObjectDropdownProps";
import {VehicleSerializer} from "../../contracts/holotrak/vehicle";


const VehiclesDropdown: React.FC<IHoloObjectDropdownProps> = ({fieldName, onBlur, onClear, onSelect}) => {
    const form = Form.useFormInstance();
    const currentValue = form.getFieldValue(fieldName);

    const [vehicleSearch, setVehicleSearch] = React.useState<string>("");
    const {isLoading: isLoadingVehiclesList, vehicles} = useGetPaginatedVehiclesListQuery({
        page: 0,
        limit: 15,
        fieldValue: '',
        searchValue: vehicleSearch,
    }, {
        selectFromResult: (result) => {
            return {
                isLoading: result.isLoading,
                vehicles: result?.data?.items.map(v => VehicleSerializer.parse(v)) ?? []
            }
        }
    });

    const vehicleOptions = useMemo(() => {
        const completeOptions = [
            {value: "vehicles", label: <>Vehicles</>}
        ]
        if (!isLoadingVehiclesList) {
            vehicles?.forEach((vehicle) => {
                completeOptions.push(
                    ({
                        value: vehicle.id,
                        label: (
                            <Row gutter={4}>
                                <Col span={2} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}>
                                    <Badge color={
                                        vehicle.driver ? COLOR_OFFLINE : COLOR_ONLINE
                                    } title='Vehicle Assigned to a Driver'/>
                                </Col>
                                <Col span={22}>
                                    <Typography.Text ellipsis={{
                                        tooltip: vehicle.name
                                    }}>
                                        {vehicle.name}
                                    </Typography.Text>
                                </Col>

                            </Row>
                        ),
                    })
                );
            })
        }

        return completeOptions;
    }, [isLoadingVehiclesList, vehicles]);


    const ownOnSelect = useCallback((value: any, option: any) => {
        form.setFieldValue(fieldName, value);

        if (onSelect) {
            onSelect(value, option);
        }
    }, [fieldName, form, onSelect]);

    const ownOnClear = useCallback(() => {
        form.setFieldValue(fieldName, undefined);

        if (onClear) {
            onClear();
        }
    }, [fieldName, form, onClear]);

    return (
        <Select
            allowClear
            showSearch
            filterOption={false}
            onClear={ownOnClear}
            onSelect={ownOnSelect}
            onBlur={onBlur}
            value={currentValue}
            onSearch={(value) => setVehicleSearch(value)}
            options={vehicleOptions}
        />
    )
}

export default VehiclesDropdown;
