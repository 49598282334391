import {Select} from "antd";
import React, {useMemo} from "react";
import {useGetIndoorLayoutListQuery} from "../../redux/services/losant/indoorLayouts";
import {indoorLayoutSerializer} from "../../contracts/holotrak/indoorLayout";


const IndoorLayoutDropdown = ({onClear, onSelect, defaultValue = null, value = null}) => {
    const [indoorLayoutSearch, setIndoorLayoutSearch] = React.useState<string>("");
    const {isLoading: isLoadingLayouts, data: indoorLayoutsApiResponse} = useGetIndoorLayoutListQuery({
        page: 0,
        limit: 15,
        fieldValue: indoorLayoutSearch,
        searchValue: indoorLayoutSearch,
    }, {
        selectFromResult: (result) => {
            return {
                data: result.data?.map((layout) => indoorLayoutSerializer.parse(layout)) || [],
                isLoading: result.isLoading,
            }
        },
    });

    const indoorLayoutOptions = useMemo(() => {
        const completeOptions = []
        if (!isLoadingLayouts) {
            indoorLayoutsApiResponse.forEach((layout) => {

                completeOptions.push(
                    ({
                        value: layout.id,
                        label: layout.label,
                    })
                );
            })
        }

        return completeOptions;
    }, [isLoadingLayouts, indoorLayoutsApiResponse]);

    const valueProps = useMemo(() => {
        if (!value && !defaultValue) {
            return null;
        }
        const newVar: { defaultValue?: string, value?: string } = {};

        if (defaultValue) {
            newVar.defaultValue = defaultValue;
        }

        if (value) {
            newVar.value = value;
        }

        return newVar;
    }, [defaultValue, value]);


    return (
        <Select
            showSearch
            filterOption={false}
            onClear={onClear}
            onSelect={onSelect}
            onSearch={(value) => setIndoorLayoutSearch(value)}
            style={{width: 160}}
            options={indoorLayoutOptions}
            {...valueProps}
        />
    )
}

export default IndoorLayoutDropdown;
